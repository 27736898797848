import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputMask from 'react-input-mask';
import Hero from '../Hero';
import Clientdata from '../Clientdata';
import styles from './Mainhome.module.css'

// Import all images
import homeabt from '../../../Assets/Home/hoem_abt.webp';
import Painkiller from '../../../Assets/Home/pain_killer.webp';
import softgel from '../../../Assets/Home/softgel.webp';
import LiquidDrySyrups from '../../../Assets/Home/LiquidDrySyrups.png';
import Nasaldrops from '../../../Assets/Home/Nasaldrop.webp';
import Antidiabetic from '../../../Assets/Home/Antidiabetic.webp';
import Cardiac from '../../../Assets/Home/Cardiac.jpg';
import Gynecology from '../../../Assets/Home/Gynecology.jpg';
import Paediatric from '../../../Assets/Home/paediatric.jpg';
import Orthopedic from '../../../Assets/Home/Orthopedic.jpg';
import Gastrology from '../../../Assets/Home/Gastrology.jpg';

const Mainhome = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };

    const aboutRef = useRef(null);
    const productsRef = useRef(null);
    const contactRef = useRef(null);
    const clientsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.animate);
                    }
                });
            },
            { threshold: 0.1 }
        );

        [aboutRef, productsRef, contactRef, clientsRef].forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <Hero />
            <div className={styles.main_home}>
                {/* Home About Section */}
                <section ref={aboutRef} className={`${styles.home_about} ${styles.fadeIn}`}>
                    <div className={styles.home_about_image}>
                        <img src={homeabt} alt='BJS Healthcare about' />
                    </div>

                    <div className={styles.home_about_content}>
                        <h4>About Us</h4>
                        <h2>Welcome To BJS Healthcare</h2>
                        <p>BJS HEALTHCARE PVT LTD. is the fastest-emerging, well-known ISO 9001-2015, WHO-GMP certified.  is driven by a team of highly committed, dedicated, and experienced pharmaceutical professionals. Our commitment to excellence sets us apart in the dynamic landscape of the pharmaceutical industry. <br /> <br />At BJS Healthcare, we specialize in the research, development, and manufacturing of high-quality pharmaceutical products that address a wide range of health conditions. Our portfolio includes innovative medicines, generic formulations, and over-the-counter products that are trusted by healthcare professionals and patients worldwide.</p>
                        <button className={styles.readmore}>
                            <Link to='/About' className={styles.links}><span>Read More</span></Link>
                        </button>
                    </div>
                </section>
            </div>

            {/* Home Product Range Section */}
            <div ref={productsRef} className={`${styles.home_product_range} ${styles.fadeIn}`}>
                <h2>Our Products Range</h2>
                <Slider {...settings} className={styles.product_range_cards}>
                    {[
                        { img: Painkiller, title: 'Pain killer' },
                        { img: softgel, title: 'Softgel' },
                        { img: LiquidDrySyrups, title: 'Liquid and Dry Syrups' },
                        { img: Nasaldrops, title: 'Nasal Drops' },
                        { img: Antidiabetic, title: 'Antidiabetic' },
                        { img: Cardiac, title: 'Cardiac' },
                        { img: Gynecology, title: 'Gynecology' },
                        { img: Paediatric, title: 'Paediatric' },
                        { img: Orthopedic, title: 'Orthopedic' },
                        { img: Gastrology, title: 'Gastrology' },
                    ].map((product, index) => (
                        <div key={index} className={styles.product_card_container}>
                            <div className={styles.product_card}>
                                <img src={product.img} alt={`BJS Healthcare ${product.title} Product Range`} />
                                <div className={styles.text}>
                                    <h2>{product.title}</h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Home Contact Section */}
            <div ref={contactRef} className={`${styles.home_contact} ${styles.fadeIn}`}>
                <div className={styles.home_contact_main}>
                    <div className={styles.contact_form}>
                        <h2>Contact Us</h2>
                        <p>We will resolve your inquiry within 24 hours.</p>
                        <div className={styles.contact_form_main}>
                            <form>
                                <div className={styles.inputs}>
                                    <input type='text' name='fullname' placeholder='Full Name*' required />
                                    <input type='email' name='email' placeholder='Email*' required />
                                </div>

                                <div className={styles.inputs}>
                                    <InputMask
                                        mask="+91 99999 99999"
                                        maskChar=""
                                        pattern="\+91\s\d{5}\s\d{5}"
                                        required
                                    >
                                        {(inputProps) => (
                                            <input
                                                {...inputProps}
                                                type="tel"
                                                name="phonenumber"
                                                placeholder="Phone no*"
                                            />
                                        )}
                                    </InputMask>
                                    <input type='text' name='city' placeholder='City*' required />
                                </div>
                                <div className={styles.inputs}>
                                    <textarea name='message' placeholder='Message*' required />
                                </div>

                                <button type='submit' className={styles.readmore}>
                                    <span className={styles.links}>Submit Now</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Clients Section */}
            <div ref={clientsRef} className={`${styles.home_clients} ${styles.fadeIn}`}>
                <h2>Clients</h2>
                <div className={styles.client_grid}>
                    {Clientdata.map(client => (
                        <div key={client.id} className={styles.client_image}>
                            <img src={client.image} alt={`Client ${client.id}`} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Mainhome;