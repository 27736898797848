const Productdata = [
    {
        id:1,
        name: 'Amoxycillin Trihydrate 250mg',
        category: 'Betalactam', 
        subcategory: 'Tablets',
    },
    {
        id:2,
        name: 'Amoxycillin Trihydrate 500mg ',
        category: 'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:3,
        name: 'Amoxycillin Trihydrate 200mg + Potassium Clavulanate 28.5mg ',
        category: 'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:4,
        name:'Amoxycillin Trihydrate 250mg + Potassium Clavulanate 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id: 5,
        name: 'Amoxycillin Trihydrate 400mg + Potassium Clavulanate 57mg',
        category: 'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:6,
        name:'Amoxycillin Trihydrate 500mg + Potassium Clavulanate 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },

    {
        id: 7,
        name:'Amoxycillin Trihydrate 875mg + Potassium Clavulanate 125mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:8,
        name:'Cefixime Trihydrate 50m',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:9,
        name:'Cefixime Trihydrate 100mg',
        category: 'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:10,
        name:'Cefixime Trihydrate 200mg',
        category: 'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:11,
        name: 'Cefixime Trihydrate 400mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:12,
        name:'Cefixime Trihydrate 100mg + Potassium Clavulanate 62.5mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:13,
        name:'Cefixime Trihydrate 200mg + Potassium Clavulanate 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:14,
        name:'Cefixime Trihydrate 100mg + Ofloxacin 100mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:15,
        name:'Cefixime Trihydrate 200mg + Ofloxacin 200mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:16,
        name:'Cefixime Trihydrate 200mg + Ornidazole 500mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:17,
        name:'Cefixime Trihydrate 200mg + Azithromycin 250mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:18,
        name: 'Cefixime Trihydrate 200mg + Azithromycin 500mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:19,
        name:'Cefixime Trihydrate 200mg + Linezolid 600mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:20,
        name:'Cefixime Trihydrate 200mg + Dicloxacillin Sodium 500mg ',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:21,
        name:'Cefpodoxime Proxetil 50mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:22,
        name:'Cefpodoxime Proxetil 100mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:23,
        name:'Cefpodoxime Proxetil 200mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:24,
        name:'Cefpodoxime Proxetil 500g + Potassium Clavulanate 28.5mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:25,
        name:'Cefpodoxime Proxetil 100mg + Potassium Clavulanate 62.5mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:26,
        name:'Cefpodoxime Proxetil 200mg + Potassium Clavulanate 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    {
        id:27,
        name:'Cefuroxime Axetil 250mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:28,
        name:'Cefuroxime Axetil 500mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:29,
        name:'Cefuroxime Axetil 250mg + Potassium Clavulanate 125mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:30,
        name:'Cefuroxime Axetil 500mg + Potassium Clavulanate 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',

    },
    
    {
        id:31,
        name:'Cefuroxime Axetil 500mg + Linezolid 600mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:32,
        name:'Cefadroxil 125mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:33,
        name:'Cefadroxil 250mg  ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:34,
        name:'Cefadroxil 500mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:35,
        name:'Cephalexin 125mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:36,
        name:'Cephalexin 250mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:37,
        name:'Cephalexin 500mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:38,
        name:'Cephalexin 500mg + Probenacid 250mg',
        category:'Betalactam',
        subcategory: 'Tablets',
    },
    {
        id:39,
        name:'Faropenem Sodium 200mg ',
        category:'Betalactam',
        subcategory: 'Tablets',
    },

    {
        id:40,
        name:'Amoxycillin Trihydrate 125mg ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:41,
        name:'Amoxycillin Trihydrate 250mg ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:42,
        name:'Amoxycillin Trihydrate 500mg ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:43,
        name:'Amoxycillin Trihydrate 250mg + Dicloxacillin 250mg  ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:44,
        name:'Ampicillin Anhydrous 125mg + Flucloxacillin Sodium 125mg   ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:45,
        name:'Ampicillin Anhydrous 250mg + Flucloxacillin Sodium 250mg ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },
    {
        id:46,
        name:'Cephalexin 250mg',
        category:'Betalactam',
        subcategory: 'Capsules',
    },

    {
        id:47,
        name:'Cephalexin 500mg',
        category:'Betalactam',
        subcategory: 'Capsules',
    },
    {
        id:48,
        name:'Cephalexin 500mg + Probenacid 250mg ',
        category:'Betalactam',
        subcategory: 'Capsules',
    },
    {
        id:49,
        name:'Amoxycillin Trihydrate 125mg',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },

    {
        id:50,
        name:'Cefixime Trihydrate 50mg',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },

    {
        id:51,
        name:'Cefixime Trihydrate 100mg + Ofloxacin 100mg',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },
    {
        id:52,
        name:'Cefpodoxime Proxetil 50mg ',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },

    {
        id:53,
        name:'Cefpodoxime Proxetil 100mg   ',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },

    {
        id:54,
        name:'Cefuroxime Axetil 125mg ',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },
    
    {
        id:55,
        name:'Linezolid 100mg',
        category:'Betalactam',
        subcategory: 'Dry Syrups',
    },

    {
        id:56,
        name:'Amoxicillin Sodium 1000mg + Clavulanic Acid 200mg',
        category:'Injectable',
        subcategory:'Dry Powder Injection',
    },

    {
        id:57,
        name:'Ceftriaxone Sodium 125mg',
        category:'Injectable',
        subcategory:'Dry Powder Injection',
    },
    {
        id:58,
        name:'Ceftriaxone Sodium 250mg',
        category:'Injectable',
        subcategory:'Dry Powder Injection',
    },

    {
        id:59,
        name:'Ceftriaxone Sodium 500mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
        
    },
    {
        id:60,
        name:'Ceftriaxone Sodium 1000mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
        
    },

    {
        id:61,
        name:'Ceftriaxone Sodium 2000mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
        
    },
    {
        id:62,
        name:'Ceftriaxone Sodium 250mg + Sulbactam Sodium 125mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
        
    },

    {
        id:63,
        name:'Ceftriaxone Sodium 500mg + Sulbactam Sodium 250mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:64,
        name:'Ceftriaxone Sodium 1000mg + Sulbactam Sodium 500mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:65,
        name:'Ceftriaxone Sodium 250mg +Tazobactam Sodium 31.25mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:66,
        name:'Ceftriaxone Sodium 500mg +Tazobactam Sodium 62.5mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:67,
        name:'Ceftriaxone Sodium 1000mg +Tazobactam Sodium 125mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:68,
        name:'Cefotaxime Sodium 250mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },
    {
        id:69,
        name:'Cefotaxime Sodium 500mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },
    {
        id:70,
        name:'Cefotaxime Sodium 1000mg',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:71,
        name:'Cefoparazone Sodium 1000mg + Sulbactum Sodium 500m',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },
    {
        id:72,
        name:'Cefoparazone Sodium 2000mg + Sulbactum Sodium 1000mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:73,
        name:'Meropenem 250mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:74,
        name:'Meropenem 500mg  ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:75,
        name:'Meropenem 100mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },

    {
        id:76,
        name:'Piperacilline Sodium 4gm+Tazobactam Sodium 500mg ',
        category:'Injectable',
       subcategory:'Dry Powder Injection',
    },


    // Non Betalactam 


    {
        id:77,
        name:'Acebrofylline 100 mg / 200 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:78,
        name:'Acebrofylline 200 mg + Montelukast Sodium 10 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:79,
        name:'Aceclofenac 100 mg / 200 tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:80,
        name:'Aceclofenac 200 mg SR tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:81,
        name:'Aceclofenac 100 mg + Thiocolchicoside 4 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:82,
        name:'Aceclofenac 100 mg + Thiocolchicoside 8 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:83,
        name:'Aceclofenac 100 mg + Para 325 mg + thiocolchicoside 4 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:84,
        name:'Aceclofenac 100 mg + Paracetamol 325 mg + Serratio 10 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:85,
        name:'Aceclofenac 100 mg + Paracetamol 325 mg + Serratio 15 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:86,
        name:'Aceclofenac 100 mg + Paracetamol 325 mg + Thiocolchicoside 4 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:87,
        name:'Aceclofenac 100 mg + Paracetamol 325 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:88,
        name:'Aciclovir 200 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:89,
        name:'Adenosylcobalamin 15 mcg + Ferrous As.100 mg + Folic Acid 1.5 mg + Zinc 22.5 mg',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:90,
        name:'ALA 100 mg + Folic Acid 1.5 mg + Meco 1500 mcg + Pyridoxine 3 mg + D3- 1000 IU ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:91,
        name:'Albendazole 400 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:92,
        name:'Allopurinol 100 mg / 300 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:93,
        name:'Alprazolam 0.25 / 0.50 mg tablets  ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:94,
        name:'Alprazolam 0.25 mg + Propranolol 20 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:95,
        name:'Alprazolam 0.25 mg + Fluoxetine 20 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:96,
        name:'Alprazolam 0.25 Mg + Propranolol 10 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:97,
        name:'Amisulpride 50 mg / 100 mg / 200 mg tablets  ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:98,
        name:'Amitriptyline 10 mg / 25 mg / 50 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:99,
        name:'Amitriptyline 12.5 mg + Chlordiazepoxide 5 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:100,
        name:'Amitriptyline 25 mg + Chlordiazepoxide 10 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:101,
        name:'Amitriptyline 25 mg + Chlordiazepoxide 5 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:102,
        name:'Amlodipine 10 mg + Olmesartan Medoxomil 5 mg + Hydrochlorothiazide 12.5 mg',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:103,
        name:'Amlodipine 2.5 mg / 5mg / 10 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:104,
        name:'Amlodipine Besylate 5 mg + Atenolol 50 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:105,
        name:'Aripiprazole 10 mg / 15 mg / 20 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:106,
        name:'Artemether 20 mg + Lumefantrine 120 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:107,
        name:'Artemether 40 mg + Lumefantrine 240 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:108,
        name:'Artemether 80 mg + Lumefantrine 480 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:109,
        name:'Artemether 20 mg + Lumefantrine 120 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:110,
        name:'Artemether 40 mg + Lumefantrine 240 mg tablets  ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:111,
        name:'Artemether 80 mg + Lumefantrine 480 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:112,
        name:'Atenolol 25 mg / 50 mg / 100 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:113,
        name:'Atorvastatin 10 mg / 20 mg /40 mg Tablets  ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:114,
        name:'Atorvastatin 10 mg + Ezetimibe 10 mg Tablets   ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:115,
        name:'Atorvastatin 10 mg + Fenofibrate 160 mg tablets    ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:116,
        name:'Atorvastatin Calcium 10 mg + Aspirin 75 mg tablets   ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:117,
        name:'Azathioprine 25 mg / 50 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:118,
        name:'Azithromycin 100 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:119,
        name:'Azithromycin 250 mg / 500 mg tablets  ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:120,
        name:'Baclofen 10 mg / 20mg / 30 mg / 60 mg tabs ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:121,
        name:'Betahistine Dihydrochloride 8 mg / 16 mg / 24 mg tablets SR',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:122,
        name:'Calcium Carbonate 1250 mg + Vitamin D3- 500 IU + Zinc 4 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:123,
        name:'Calcium Carbonate 1250 mg + Vitamin D3- 500 IU tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:124,
        name:'Calcium Carbonate 1250 mg eq to Ele Calcium 500 mg + Vitamin D3- 500 IU tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:125,
        name:'Calcium Carbonate 1250 mg eq. to Calcium 500 mg + Calcitrol 0.25 mcg + Elemental Zinc 7.5 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:126,
        name:'Calcium Carbonate 1250 mg eq. to Calcium 500 mg + Mg. Hydroxide 100 mg + Zinc 4 mg + Vitamin D3- 200 IU tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:127,
        name:'Calcium Carbonate 1250 mg eq. to Calcium 500 mg + Vit D3- 250 IU tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:128,
        name:' Calcium Carbonate 1250 mg eq. to Calcium 500 mg + Vit D3- 250 IU + Mg. Hydroxide 40 mg + Mg. Sulphate 1.8 mg + Zinc Sul. 33 mg + Copper Sul phate 1 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:129,
        name:' Calcium Carbonate 1250 mg eq. to Calcium 500 mg + Vitamin D3- 400 IU + L-Lysine Mono 15 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:130,
        name:' Calcium Carbonate 625 mg + Zinc 7.5 mg + Calcitriol 0.25 mcg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:131,
        name:' Calcium citrate 1000 mg + Mag. Hydroxide 100 mg + Vit D3- 200 IU + Zinc 4 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:132,
        name:'Calcium citrate 1000 mg + Magnesium Hydroxide 100 mg + Zinc 7.5 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:133,
        name:'Calcium citrate 1180 mg + Vit D3- 250 IU + Zinc Sulphate 7.5 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:134,
        name:'Calcium Citrate 1000 mg + Calcitriol 0.25 mcg + Zinc 7.5 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:135,
        name:'Calcium Citrate 250 mg + Vit D3- 250 IU + Zinc 7.5 mg + Magnesium 25 mg + Manganese 2.5 mg + Copper 0.75 mg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:136,
        name:'Calcium citrate 500 mg + Calcitrol 0.25 mcg tablets ',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:137,
        name:'Calcium citrate 500 mg + Calcitrol 0.25 mcg + Zinc 7.5 mg tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:138,
        name:'Calcium citrate 500 mg + El. Magnesium 50 mg + Zinc 4 mg + Vit D3- 250 IU tablets',
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        // Need to change after product list complete
        id:139,
        name:'tablets',    
        category:'Nonbetalactam',
    },

    {
        
        id:140,
        name:'Calcium citrate Malate 500 mg + Vit D3- 300 IU + Zinc Sulphate 7.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:141,
        name:'Calcium citrate malate Eq Calcium 252 mg + Vit D3- 1000 IU + Mg. Hydroxide 100 mg + Zinc 7.5 mg + Copper Sulphate 1 mg + Borox 0.25 mg + Mg. Sulphate 1.8 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:142,
        name:'Calcium Lactate 300 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:143,
        name:'Carbamazepine 200 mg / 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:144,
        name:'Cetirizine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:145,
        name:'Cetirizine 2.5 mg + Phenylephrine 2.5 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:146,
        name:'Cetirizine 5 mg + Ambroxol 60 mg + Phenylephrine 5 mg + Para 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:147,
        name:'Chlordiazepoxide 10 mg + Trifluoperazine 1 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:148,
        name:'Chlordiazepoxide 5 mg + Clidinium Bromide 2.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:149,
        name:'Chlordiazepoxide 5 mg + Propranolol 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:150,
        name:'Chlorpromazine 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:151,
        name:'Cholecalciferol (Vitamin D3) 1000 IU / 60,000 IU tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:152,
        name:'Cilnidipine 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:153,
        name:'Cinitapride 1 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:154,
        name:'Cinnarizine 15 mg / 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:155,
        name:'Cinnarizine 20 mg + Dimenhydrinate 40 mg tablets MD',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:156,
        name:'Cinnarzine 20 mg + Domperidone 15 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:157,
        name:'Cinnarzine 20 mg + Domperidone 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:158,
        name:'Cinnarzine 25 mg tablets s',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:159,
        name:'Ciprofloxacin 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:160,
        name:'Ciprofloxacin 500 mg + Tinidazole 600 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },

    {
        id:161,
        name:'Citicoline 500 mg + Piracetam 400 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:162,
        name:'Citicoline 500 mg + Piracetam 800 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:163,
        name:'Citicoline Sodium Eq to Citicoline 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:164,
        name:'Citicoline Sodium 500 mg + Piracetam 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:165,
        name:'Clarithromycin 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:166,
        name:'Clindamycin 150 mg / 300 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:167,
        name:'Clobazam 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:168,
        name:'Clonazepam 0.25 mg + Escitalopram 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:169,
        name:'Clonazepam 0.50 mg + Escitalopram 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:170,
        name:'Clonazepam 0.50 mg + Escitalopram 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:171,
        name:'Clonazepam 0.25 mg / 0.50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:172,
        name:'Clonazepam 0.25 mg + Propranolol 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:173,
        name:'Clonazepam 0.5 mg / 1 mg / 2 mg tablets MD',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:174,
        name:'Clonazepam 0.5 mg + Propranolol 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:175,
        name:'Clopidogrel 75 mg + Aspirin 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:176,
        name:'Clopidogrel 75 mg / 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:177,
        name:'Clopidogrel 75 mg + Aspirin 150 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:178,
        name:'Clopidogrel Bisulphate 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:179,
        name:'Clopidogrel Bisulphate 75 mg + Aspirin 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:180,
        name:'Clozapine 25 mg / 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:181,
        name:'Dapoxetine 30 mg / 60 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:182,
        name:'Deflazacort 6 mg / 12 mg / 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:183,
        name:'Desloratadine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:184,
        name:'Desvenlafaxine 50 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:185,
        name:'Diacerein 50 mg + Glucosamine 750 mg + Methyl Sulfonyl Methane 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:186,
        name:'Diacerine 50 mg + Glucosamine 750 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:187,
        name:'Diclofenac Potassium 50 mg + Metaxalone 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:188,
        name:'Diclofenac Potassium 50 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:189,
        name:'Diclofenac Potassium 50 mg + Serratiopeptidase 10 mg + Para 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:190,
        name:'Diclofenac Potassium 50 mg + Serratiopeptidase 10 mg tablet',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:191,
        name:'Diclofenac Potassium 50 mg + Serratiopeptidase 15 mg + Para 325 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:192,
        name:'Diclofenac Potassium 75 mg + Serratiopeptidase 15 mg + Para 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:193,
        name:'Diclofenac Sodium 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:194,
        name:'Diclofenac sodium 50 mg + Para 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:195,
        name:'Diclofenac sodium 50 mg + Para 325 mg + Chlorzoxazone 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:196,
        name:'Diclofenac sodium 50 mg + Para 325 mg + Mg. Trisilicate 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:197,
        name:'Dicyclomine 10 mg + Mefenamic Acid 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:198,
        name:'Dicyclomine 10 mg + Mefenamic Acid 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:199,
        name:'Dicyclomine 20 mg + Mefenamic Acid 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:200,
        name:'Dicyclomine 20 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:201,
        name:'Dicyclomine Hcl 10 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:202,
        name:'Divalproex Sodium 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:203,
        name:'Divalproex Sodium 750 / 1000 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:204,
        name:'Domperidone 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:205,
        name:'Donepezil Hydrochloride 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:206,
        name:'Dothiepin 25 mg / 50 mg / 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:207,
        name:'Doxofyline 400 mg + Ambroxol hydrochloride 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:208,
        name:'Doxofyline 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:209,
        name:'Doxofyline 650 mg tablets Uncoated SR',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:210,
        name:'Doxofylline 400 mg + Ambroxol 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:211,
        name:'Doxofylline 400 mg + Montelukast 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:212,
        name:'Doxycycline 100 mg + Lactic Acid Bacillus 5 Billion spores tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:213,
        name:'Doxylamine Succinate 10 mg + Pyridoxine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:214,
        name:'Doxylamine Succinate 20 mg + Pyridoxine 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:215,
        name:'Drotaverine Hydrochloride 80 mg + Aceclofenac 100 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:216,
        name:'Drotaverine Hydrochloride 80 mg + Mefenamic Acid 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:217,
        name:'Drotaverine Hydrochloride 80 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:218,
        name:'Duloxetin 20 mg / 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:219,
        name:'Dutasteride 0.5 mg tablet',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:220,
        name:'Enalapril Maleate 2.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:221,
        name:'Erythromycin 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:222,
        name:'Escitalopram 5 mg / 10 mg / 20 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:223,
        name:'Escitalopram 10 mg + Clonazepam 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:224,
        name:'Escitalopram 20 mg + Clonazepam 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:225,
        name:'Escitalopram 5 mg + Clonazepam 0.25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id: 226,
        name:'Esomeprazole 20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id: 227,
        name:'Etizolam 0.25 mg / 0.50 mg / 1 mg tablets & MD',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:228 ,
        name:'Etizolam 0.50 mg + Propranolol 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id: 229,
        name:'Etizolam 0.50 mg + Propranolol 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id: 230,
        name:'Etizolam 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id: 231,
        name:'Etizolam 0.50 mg + Escitalopram 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:232 ,
        name:'Etizolam 0.50 mg + Propranolol Hydrochloride 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:233 ,
        name:'Etoricoxib 60 mg & Thiocolchicoside 4 mg Tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:234 ,
        name:'Etoricoxib 60 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:235 ,
        name:'Etoricoxib 90 mg / 120 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:236 ,
        name:'Famotidine 20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:237 ,
        name:'Febuxostat 40 mg / 80 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:238 ,
        name:'Ferrous Ascorbate 100 mg + Folic Acid 1.5 mg + Zinc 22.5 mg tablet',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:239 ,
        name:'Ferrous Ascorbate 100 mg + Folic Acid 1.5 mg + Cyanocobalamin 15 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:240 ,
        name:'Ferrous Ascorbate 100 mg + Folic Acid 1.5 mg + Mecobalamin 1500 mcg + Zinc 22.5 mg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:241 ,
        name:'Ferrous Ascorbate 100 mg + Folic Acid 1.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:242 ,
        name:'Ferrous Ascorbate 100 mg + Folic Acid 500 mcg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:243 ,
        name:'Ferrous Bis Glycinate 60 mg + Folic Acid 1 mg + Zinc Bis Glycinate 15 mg + Meco 1500 mcg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:244 ,
        name:'Ferrous Fumarate 152 mg eq to 50 mg + Folic Acid 150 mcg + Zinc Sulphate 22.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:245 ,
        name:'Ferrous Fumarate 210 mg eq. to Iron 69 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:246 ,
        name:'Ferrous Sulphate 100 mg + Folic Acid 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:247 ,
        name:'Fexofenadine 120 mg / 180 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:248 ,
        name:'Fexofenadine 120 mg + Montelukast 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:249 ,
        name:'Fexofenadine Hydrochloride 120 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:250 ,
        name:'Finasteride 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:251 ,
        name:'Flavoxate 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:252 ,
        name:'Fluconazole 50 mg / 150 mg Tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:253 ,
        name:'Flunarizine 5mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:254 ,
        name:'Flunarizine Dihyrochloride 10 mg + Propranolol HCl 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:255 ,
        name:'Fluoxetine 20 mg /40 mg / 60 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:256 ,
        name:'Flupentixol 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:257 ,
        name:'Flupentixol 0.5 mg + Escitalopram 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:258 ,
        name:'Fluvoxamine 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:259 ,
        name:'Folic Acid 400 mcg / 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:260 ,
        name:'Frusemide 20 mg + Spironolactone 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:261 ,
        name:'Frusemide 20 mg + Spironolactone 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:262 ,
        name:'Frusemide20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:263 ,
        name:'Gabapentin 100 mg + Amitriptyline 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:264 ,
        name:'Gabapentin 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:265 ,
        name:'Gabapentin 300 mg + Nortriptyline 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:266 ,
        name:'Gabapentin 300 mg + Mecobalamin 500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:267 ,
        name:'Gabapentin 300 mg tablets Uncoated SR ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:268 ,
        name:'Glibenclamide 2.5 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:269 ,
        name:'Glibenclamide 5 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:270 ,
        name:'Glibenclamide 5 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:271 ,
        name:'Gliclazide 30 mg / 60 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:272 ,
        name:'Gliclazide 40 mg / 80 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:273 ,
        name:'Gliclazide 40 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:274 ,
        name:'Gliclazide 80 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:275 ,
        name:'Glimepiride 0.5 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:276 ,
        name:'Glimepiride 1 mg / 2 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:277 ,
        name:'Glimepride 1 mg / 2 mg / 4 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:278 ,
        name:'Glimepride 1 mg + Metformin 1000 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:279 ,
        name:'Glimepride 1 mg + Metformin 500 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:280 ,
        name:'Glimepride 2 mg + Metformin 1000 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:281 ,
        name:'Glimepride 2 mg + Metformin 500 mg tabs ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:282 ,
        name:'Glimepride 2 mg + Metformin 500 mg tabs bilayered',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:283 ,
        name:'Glimepride 4 mg + Metformin 1000 mg tabs bilayered',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:284 ,
        name:'Glipizide 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:285 ,
        name:'Haloperidol Tablets IP 1.5 mg / 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:286 ,
        name:'Hydroxychloroquine sulfate 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:287 ,
        name:'Hydroxyzine Hydrochloride 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:288 ,
        name:'Ibuprofen 200 mg / 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:289 ,
        name:'Indomethacin 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:290 ,
        name:'Isosorbide mononitrate 5 mg / 10 mg / 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:291 ,
        name:'Isoxsurprine 10 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:292 ,
        name:'Itopride Hydrochloride 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:293 ,
        name:'Itraconazole 100 mg + Terbinafine 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:294 ,
        name:'Itraconazole 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:295 ,
        name:'Ivermectin 6 mg & Albandazole 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:296 ,
        name:'Ketoconazole 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:297 ,
        name:'Ketorolac Tormethamine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:298 ,
        name:'Levetiracetam 250 mg / 500 mg / 1000 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:299 ,
        name:'Levocetirizine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:300 ,
        name:'Levocetrizine 2.5 mg + Montelukast 4 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:301 ,
        name:'Levocetrizine 5 mg + Montelukast 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:302 ,
        name:'Levocetrizine MD 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:303 ,
        name:'Levofloxacin 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:304 ,
        name:'Levofloxacin 250 mg + Ambroxol 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:305 ,
        name:'Levofloxacin 500 mg + Ambroxol 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:306 ,
        name:'Levofloxacin 500 mg / 750 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:307 ,
        name:'Levosulpride 25/50/100/150/200 mg ER tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:308 ,
        name:'Lincomycin Hydrochloride 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:309 ,
        name:'Lithium Carbonate 300 mg /450 mg tablets IP',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:310 ,
        name:'Lornoxicam 8 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:311 ,
        name:'Lornoxicam 8 mg + Thiocolchicoside 4 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:312 ,
        name:'Lornoxicam 16 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:313 ,
        name:'Lornoxicam 8 mg + Thiocolchicoside 4 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:314 ,
        name:'Losartan Potassium 25 mg / 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:315 ,
        name:'Losartan Pott. 100 mg + Hydrochlorothiazide 12.5 mg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:316 ,
        name:'Losartan Pott. 50 mg + Hydrochlorothiazide 12.5 mg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:317 ,
        name:'Mecobalamin 1500 mcg + Alpha Lipoic Acid 200 mg + Pyridoxine 3 mg + Folic Acid 1.5 mg ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:318 ,
        name:'Mecobalamin 1500 mcg + Folic Acid 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:319 ,
        name:'Mecobalamin 1500 mcg + Pyridoxine 20 mg + Folic Acid 1.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:320 ,
        name:'Mecobalamin 1500 mcg + Pyridoxine 20 mg + Folic Acid 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:320 ,
        name:'Mecobalamin 1500 mcg + Pyridoxine 5 mg + Folic Acid 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:321 ,
        name:'Mecobalamin 500 mcg / 1500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:322 ,
        name:'Mecobalamin 750 mcg + Vit B1- 10 mg + Vit B6- 3 mg + Calcium Pantothenate 50 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:323 ,
        name:'Memantine 10 mg + Donepezil 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:324 ,
        name:'Memantine 5 mg + Donepezil 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:325 ,
        name:'Memantine Hcl 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:326 ,
        name:'Metformin 1000 mg + Glimepiride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:327 ,
        name:'Metformin 500 mg SR tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:328 ,
        name:'Metformin 500 mg / 1000 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:329 ,
        name:'Metformin 500 mg + Voglibose 0.2 mg + Glimepiride 1 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:330 ,
        name:'Metformin 500 mg + Voglibose 0.2 mg + Glimepiride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:331 ,
        name:'Metformin 500 mg + Voglibose 0.2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:332 ,
        name:'Metformin 500 mg + Voglibose 0.3 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:333 ,
        name:'Metformin 500 mg + Glimepiride 1 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:334 ,
        name:'Metformin 500 mg + Glimepiride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:335 ,
        name:'Metformin Hydrochloride 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:336 ,
        name:'Methylcobalamin 1500 mcg + ALA 200 mg + Pyridoxine 3 mg + Folic Acid 1.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:337 ,
        name:'Methylcobalamin 500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:338 ,
        name:'Methylprednisolone 4 mg / 8 mg / 16 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:339 ,
        name:'Metoprolol 25 mg + Chlorthalidone 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:340 ,
        name:'Metoprolol 50 mg + Chlorthalidone 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:341 ,
        name:'Metoprolol 25 mg + Telmisartan 40 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:342 ,
        name:'Metoprolol Succinate Eq to Metoprolol Tartrate 25 mg + Chlorthalidone 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:343 ,
        name:'Metoprolol Succinate Eq to Metoprolol Tartrate 50 mg + Chlorthalidone 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:344 ,
        name:'Metoprolol Tartrate 25 mg / 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:345 ,
        name:'Metoprolol Tartrate 50 mg + Ramipril 2.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:346 ,
        name:'Metoprolol Tartrate 50 mg + Ramipril 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:347 ,
        name:'Metroprolol 50 mg + Amlodipine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:348 ,
        name:'Metroprolol 50 mg + Hydrochlorthiazide 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:349 ,
        name:'Mirtazapine 7.5 mg / 15 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:350 ,
        name:'Montelukast 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:351 ,
        name:'Moxifloxacin Hydrochloride 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:352 ,
        name:'Naproxen 500 mg + Domperidone 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:353 ,
        name:'Naproxen Sodium 250 mg / 500 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:354 ,
        name:'Naproxen Sodium 500 mg + Domperidone 10 mg / 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:355 ,
        name:'Nebivolol 5 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:356 ,
        name:'Nifedipine 10 mg / 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:357 ,
        name:'Nimeuslide 100 mg & Paracetamol 325/500 mg Tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:358 ,
        name:'Nimeuslide 100 mg Tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:359 ,
        name:'Nitrofurantoin 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:360 ,
        name:'Nitroglycerin 2.6 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:361 ,
        name:'Ofloxacin 200 / 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:362 ,
        name:'Ofloxacin 200 mg & Ornidazole 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:363 ,
        name:'Ofloxacin 200 mg + Flavoxate hydrochloride 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:364 ,
        name:'Olanzapine 10 mg + Fluoxetine 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:365 ,
        name:'Olanzapine 2.5 /5 /7.5 /10 /15 / 20 mg & MD tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:366 ,
        name:'Olanzapine 5 mg + Fluoxetine 20 mg tablets uncoated MD',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:367 ,
        name:'Olanzapine 10 mg + Fluoxetine 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:368 ,
        name:'Olanzapine 5 mg + Fluoxetine 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:369 ,
        name:'Olmisartan 20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:370 ,
        name:'Olmisartan 20 mg + Hydrochlorthiazide 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:371 ,
        name:'Olmisartan 40 mg + Hydrochlorthiazide 12.5 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:372 ,
        name:'Olmisartan Medoxomil 20 mg + Amlodipine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:373 ,
        name:'Olmisartan Medoxomil 20 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:374 ,
        name:'Omeprazole Magnesium 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:375 ,
        name:'Ondensectron 4 mg / 8 mg tablets MD',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:376 ,
        name:'Ornidazole 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:377 ,
        name:'Oxcarbazepine 150 mg / 300 mg / 450 mg / 600 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:378 ,
        name:'Pancreatin 175 mg + Simethicone 50 mg + Activated Charcoal 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:379 ,
        name:'Pancreatin 192 mg + Bile Constituents 25 mg + Activated Dimethicone 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:380 ,
        name:'Pantoprazole 40 mg + Domperidone 30 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:381 ,
        name:'Pantoprazole 20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:382 ,
        name:'Pantoprazole 40 mg + Domperidone 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:383 ,
        name:'Paracetamol 325 mg + Cetirizine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:384 ,
        name:'Paracetamol 325 mg + Cetrizine 10 mg + Phenylephrine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:385 ,
        name:'Paracetamol 325 mg + Cetrizine 5 mg + Phenylephrine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:386 ,
        name:'Paracetamol 325 mg + Cetrizine 5 mg + Phenylephrine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:387 ,
        name:'Paracetamol 325 mg + Cetrizine 5 mg + Phenylephrine 5 mg tabs + Caffeine 30 mg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:388 ,
        name:'Paracetamol 325 mg + Domperidone 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:389 ,
        name:'Paracetamol 500 mg / 650 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:390 ,
        name:'Paroxetine 10 mg / 12.5 mg / 20 mg / 25mg / 37.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:391 ,
        name:'Phenobarbitone 30 mg / 60 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:392 ,
        name:'Phenylephrine 10 mg + Cetrizine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:393 ,
        name:'Phenytoin sodium 50 mg / 100 mg / 300 mg tablets SR',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:394 ,
        name:'Pioglitazone 15 mg / 30 mg tablet',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:395 ,
        name:'Piracetam 400 mg / 800 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:396 ,
        name:'Piroxicam 20 mg un dispersible tablet',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:397 ,
        name:'Pregabalin 75 mg + Nortriptyline 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:398 ,
        name:'Pregabalin 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:399 ,
        name:'Pregabalin 75 mg / 150 mg + Mecobalamin 1500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:400 ,
        name:'Pregabalin 75 mg + Mecobalamin 750 mcg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:401 ,
        name:'Pregabalin 75 mg / 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:402 ,
        name:'Pregabalin 75 mg + Mecobalamin 1500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:403 ,
        name:'Pregabalin 75 mg + Nortriptyline 10 mg + Mecobalamin 1500 mcg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:404 ,
        name:'Pregabalin 75 mg + Nortriptyline 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:405 ,
        name:'Prochlorperazine Maleate 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:406 ,
        name:'Promethazine Teoclate 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:407 ,
        name:'Propranolol 40 mg + Flunarizine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:408 ,
        name:'Propranolol 40 mg + Flunarizine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:409 ,
        name:'Propranolol 10 mg / 20 mg / 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:410 ,
        name:'Propranolol 40 mg + Clonazepam 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:411 ,
        name:'Propranolol 40 mg + Flunarizine 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:412 ,
        name:'Propranolol Hydrochloride 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:413 ,
        name:'Quetiapine Fumarate 50 mg / 100 mg / 200 mg / 300 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:414 ,
        name:'Quinine Sulfate 300 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:415 ,
        name:'Rabeprazole 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:416 ,
        name:'Rabeprazole Sodium 20 mg + Domperidone 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:417 ,
        name:'Ramipril 2.5 mg / 5 mg / 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:418 ,
        name:'Ramipril 2.5 mg + Hydrochlorothiazide 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:419 ,
        name:'Ranolazine 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:420 ,
        name:'Resperidone 2 mg + Trihexyphenidyl hydrochloride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:421 ,
        name:'Resperidone 3 mg + Trihexyphenidyl hydrochloride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:422 ,
        name:'Resperidone 4 mg + Trihexyphenidyl hydrochloride 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:423 ,
        name:'Riboflavin 10 mg + Folic Acid 1.5 mg + Niacinamide 100 mg + Lactic Acid Bacillus 60 million spores tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:424 ,
        name:'Rifaximin 400 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:425 ,
        name:'Risperidone 1 mg / 2 mg / 4 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:426 ,
        name:'Rosuvastatin 10 mg / 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:427 ,
        name:'Rosuvastatin 10 mg + Fenofibrate 160 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:428 ,
        name:'Rosuvastatin 10 mg + Fenofibrate 145 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:429 ,
        name:'Rosuvastatin 10 mg + Fenofibrate 160 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:430 ,
        name:'Rosuvastatin 20 mg + Fenofibrate 160 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:431 ,
        name:'Rosuvastatin 5 mg / 10 mg / 20 mg / 40 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:432 ,
        name:'Rosuvastatin 5 mg + Fenofibrate 67.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:433 ,
        name:'Roxithromycin 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:434 ,
        name:'Serratiopeptidase 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:435 ,
        name:'Sertraline 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:436 ,
        name:'Sertraline 50 mg + Alprazolam 0.25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:437 ,
        name:'Sildenafil Citrate 50/100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:438 ,
        name:'Silymarin 70 mg /140 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:439 ,
        name:'Sodium Picosulfate 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:440 ,
        name:'Sodium Valporate 200 mg / 300 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:441 ,
        name:'Spironolactone 25 mg / 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:442 ,
        name:'Spironolactone 25 mg + Furosemide 40 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:443 ,
        name:'Tadalafil 5 mg / 20 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:444 ,
        name:'Tamsulosin 0.4 mg + Dutasteride 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:445 ,
        name:'Tamsulosin 0.4 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:446 ,
        name:'Tamsulosin 0.4 mg + Finasteride 0.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:447 ,
        name:'Tapentadol 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:448 ,
        name:'Telmisartan 20/40/80 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:449 ,
        name:'Telmisartan 40 + Hydrochlorothiazide 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:450 ,
        name:'Telmisartan 40 mg + Chlorthalidone 12.5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:451 ,
        name:'Telmisartan 40 mg + Amlodipine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:452 ,
        name:'Telmisartan 40 mg + Ramipril 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:453 ,
        name:'Telmisartan 80 mg + Hydrochlorothiazide 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:454 ,
        name:'Teneliglipin 20 mg + Metformin 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:455 ,
        name:'Teneligliptin 20 mg tabs',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:456 ,
        name:'Terbinafine 250 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:457 ,
        name:'Thiamine Hydrochloride 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:458 ,
        name:'Thiocolchicoside 4 mg + Ketoprofen 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:459 ,
        name:'Tinidazole 300 mg / 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:460 ,
        name:'Tolperisone 150 mg + Diclofenac Sodium 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:461 ,
        name:'Tolperisone 150 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:462 ,
        name:'Tolperisone 150 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:463 ,
        name:'Tolperisone 450 mg + Diclofenac Sodium 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:464 ,
        name:'Tolperisone 450 mg SR tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:465 ,
        name:'Tolperisone Hydrochloride 150 mg + Diclofenac Sodium 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:466 ,
        name:'Tolperisone Hydrochloride 150 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:467 ,
        name:'Topiramate 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:468 ,
        name:'Torsemide 5 mg /10 mg / 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:469 ,
        name:'Torsemide 5 mg + Spironolactone 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:470 ,
        name:'Torsemide 10 mg + Spironolactone 25 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:471 ,
        name:'Torsemide 10 mg + Spironolactone 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:472 ,
        name:'Torsemide 20 mg + Spironolactone 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:473 ,
        name:'Tramadol 50 mg + Paracetamol 325 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:474 ,
        name:'Tramadol 37.5 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:475 ,
        name:'Tramadol 50 mg / 100 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:476 ,
        name:'Tramadol 50 mg + Paracetamol 325 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:477 ,
        name:'Tranexamic 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:478 ,
        name:'Tranexamic Acid 500 mg + Mefenamic Acid 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:479 ,
        name:'Tranexamic Acid 500 mg tabs + Mefenamic Acid 250 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:480 ,
        name:'Trifluoperaine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:481 ,
        name:'Trifluoperazine 1 mg + Chlordiazepoxide 10 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:482 ,
        name:'Trifluoperazine 1 mg + Chlordiazepoxide 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:483 ,
        name:'Trifluperazine 5 mg + Trihexyphenidyl 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:484 ,
        name:'Trihexyphenidyl 2 mg + Trifluoperazine 5 mg + Chlorpromazine 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:485 ,
        name:'Trihexyphenidyl HCl 2 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:486 ,
        name:'Trimethoprim 40 mg + Sulphamethoxazole 200 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:487 ,
        name:'Trypsin 48 mg + Bromelain 90 mg + Rutoside 100 mg + Aceclofenac 100 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:488 ,
        name:'Trypsin 48 mg + Bromelain 90 mg + Rutoside Trihydrate 100 mg + Diclofenac Sodium 50 mg',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:489 ,
        name:'Trypsin 48 mg + Bromelain 90 mg + Rutoside Trihydrate 100 mg tablets ',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:490 ,
        name:'Trypsin 96 mg + Bromelain 180 mg + Rutoside Trihydrate 200 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:491 ,
        name:'Ursodeoxycholic acid 150 mg / 300 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:492 ,
        name:'Venlafaxin 37.5 mg / 75 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:493 ,
        name:'Vildagliptin 50 mg + Metformin Hcl 500 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:494 ,
        name:'Vildagliptin 50 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:496 ,
        name:'Vinpocetine 5 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:497 ,
        name:'Voglibose 0.2 mg / 0.3 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
    },
    {
        id:498 ,
        name:'Zinc Sulphate 20 mg tablets',    
        category:'Nonbetalactam',
        subcategory:'Tablets',
        
    },


    // Capsules   495 is missing


    {
        id:499 ,
        name:'Acebrofylline 100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:500 ,
        name:'Baclofen 20 mg caps Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:501 ,
        name:'Carbonyl Iron 100 mg + Folic Acid 1.5 mg + Zinc Sulphate 61.8 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:502 ,
        name:'Carbonyl Iron 100 mg + Folic Acid 1.5 mg + Cyanocobalamin 15 mcg + Ascorbic Acid 75 mg + Zinc Sulphate 61.8 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:503 ,
        name:'Carbonyl Iron 100 mg + Folic Acid 1.5 mg + Cyanocobalamin 15 mcg + Zinc Sulphate 61.8 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:504 ,
        name:'Carbonyl Iron 50 mg + Folic Acid 0.5 mg + Zinc Sulphate 61.8 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:505 ,
        name:'Clindamycin 150 mg / 300 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:506 ,
        name:'Diacerein 50 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:507 ,
        name:'Doxycycline Hydrochloride 100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:508 ,
        name:'Duloxetine Hcl 20 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:509 ,
        name:'Esomeprazole Sodium 40 mg & Domperidone 30 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:510 ,
        name:'Esomeprazole Sodium 40 mg & Levosulpride 75 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:511 ,
        name:'Esomeprazole Sodium 40 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:512 ,
        name:'Fluconazole 50 mg / 150 mg capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:513 ,
        name:'Fluoxetine 20 mg / 40 mg / 60 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:514 ,
        name:'Gabapentin 300 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:515 ,
        name:'Indomethacin 25 mg / 75 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:516 ,
        name:'Itraconazole 100 mg / 200 mg capsules',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:517 ,
        name:'Lansoprazole 30 mg Capsules',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:518 ,
        name:'Lincomycin Hydrochloride 250 mg / 500 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:519 ,
        name:'Meco 1500 mcg + Alpha Lipoic Acid 100 mg + Benfotiamine 15 mg + Pyridoxine 3 mg + Calcium Pantothenate 25 mg + Folic Acid 1.5 mg + Zinc Sulphate 22.5 mg + Chromium Picolinate 65 mcg + Inosital 10 mcg + Lutein 10% - 5 mg capsules',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:520 ,
        name:'Meco 1500 mcg + Alpha Lipoic Acid 100 mg + Folic Acid 1.5 mg + Pyridoxine 3 mg + Thiamine 10 mg capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:521 ,
        name:'Meco 1500 mcg + Alpha Lipoic Acid 100 mg + Pyridoxine 3 mg Caps ',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:522 ,
        name:'Meco 1500 mcg + Alpha Lipoic Acid 100 mg + Vit B6- 3 mg + Folic Acid 1.5 mg + Benofotiamine 50 mg + Chromium 200 mcg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:523 ,
        name:'Meco 750 mcg / 1500 mcg + Alpha Lipoic Acid 100 mg + Folic Acid 1.5 mg + Pyridoxine 3 mg Caps',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:524 ,
        name:'Methylcobalamin 500 mcg + Alpha Lipoic Acid 100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:525 ,
        name:'Omeprazole 20 mg & Domperidone 10 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:526 ,
        name:'Omeprazole 20 mg & Domperidone 10 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:527 ,
        name:'Omeprazole 20 mg & Domperidone 30 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:528 ,
        name:'Orlistat 120 mg Capsule ',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:529 ,
        name:'Pantoprazole 40 mg + Domperidone 30 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:530 ,
        name:'Pantoprazole 40 mg + Levosulpride 75 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:531 ,
        name:'Pantoprazole 40 mg Enteric Coated & Itopride 150 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:532 ,
        name:'Pregabalin 50/ 75/150 / 300 mg Capsules',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:533 ,
        name:'Pregabalin 75 mg + Meco 750 mcg + ALA 100 mg + Pyridoxine 3 mg + Folic Acid 1.5 mg',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:534 ,
        name:'Pregabalin 75 mg + Mecobalamin 750 mcg Capsules',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:535 ,
        name:'Propranolol Hydrochloride 80 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:536 ,
        name:'Propranolol Hcl 40 mg (SR) Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:537 ,
        name:'Propranolol Hydrochloride 80 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:538 ,
        name:'Rabeprazole Sodium 10 mg + Aceclofenac 200 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:539 ,
        name:'Rabeprazole Sodium 20 mg + Levosulpiride 75 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:540 ,
        name:'Rabeprazole Sodium 20 mg + Domperidone 30 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:541 ,
        name:'Rabeprazole Sodium 20 mg + Aceclofenac 200 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:542 ,
        name:'Rabeprazole Sodium 20 mg + Itopride 150 mg SR Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:543 ,
        name:'Racecadotril 100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:544 ,
        name:'Rosuvastatin 10 mg + Aspirin 75 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:545 ,
        name:'Saccharomyces boulardii 282.5 mg Capsule ',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:546 ,
        name:'Silymarin 70 mg & Lecithin 262.5 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:547 ,
        name:'Tamsulosin Hydrochloride 0.4 mg + Dutastiride 0.5 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:548 ,
        name:'Tamsulosin Hydrochloride 0.4 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:549 ,
        name:'Tramadol 50 mg/ 100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
    },
    {
        id:550 ,
        name:'Zonisamide 25/50/100 mg Capsule',    
        category:'Nonbetalactam',
        subcategory:'Capsule',
       
    },
    
// LIQUIDS
    {
        id:551 ,
        name:'Ambroxol HCL 15mg + Guaiphenesin 100mg + Terbutaline Sulphate 1.25mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/Syrup',
    },
    {
        id:552 ,
        name:'Ambroxol Hydrochloride 15mg + Guaiphenesin 50mg + Terbutaline Sulphate 1.5mg + Menthol  1mg',    
        category:'Liquids/Syrup',
         subcategory:' Liquids/(Syrup/Susp.)',
    },
    {
        id:553 ,
        name:'Ambroxol Hydrochloride 15mg + Guaiphenesin 50mg + Phenylephrine HCL 5mg + Chlorpheniramine Maleate 2mg + Menthol 1mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:554 ,
        name:'Bromhexidine HCL 8mg + Dextromethorphan HBR 10mg + Ammonium Chloride 100mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:555 ,
        name:'Cetirizine Dihydrochloride 5mg + Dextromethorphan HBr 10mg + Ambroxol HCL 15mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:556 ,
        name:'Chlorpheniramine Maleate 2mg+ Ammonium Chloride 100mg + Sodium Citrate 50mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:557 ,
        name:'Chlorpheniramine Maleate 2mg+ Phenylpropanolamine HCL 10mg + Paracetamol 125mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:558 ,
        name:'Codeine Phosphate 10mg + Chlorpheniramine Maleate 4mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:559 ,
        name:'Cyproheptadine HCL 2mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:560 ,
        name:'Cyproheptadine HCL 2mg + Tricholine Citrate 275 mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:561 ,
        name:'Cyproheptadine HCL 2mg + Tricholine Citrate 275 mg + Sorbitol solution (70%) 3.575gm',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:562 ,
        name:'Deflazacort Suspension 6mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:563 ,
        name:'Dextromethorphan HBR 10mg + Phenylephrine HCL 5mg + Chlorpheniramine Maleate 2mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:564 ,
        name:'Dextromethorphan HBR 10mg + Phenylephrine HCL 5mg +  Chlorpheniramine Maleate 2mg + Menthol 2.5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:567 ,
        name:'Dextromethorphan HBR 10mg + Phenylephrine HCL 5mg + Chlorpheniramine Maleate 2mg +Paracetamol 250mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:568 ,
        name:'Dextromethorphan HBR 5mg + Cetirizine HCL 2mg + Paracetamol 250mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:569 ,
        name:'Dextromethorphan HBR 5mg + Chlorpheniramine Maleate 2.5mg + Ammonium Chloride 60mg + Guaiphenesin 50mg Menthol 5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:570 ,
        name:'Dextromethorphan HBR 5mg + Phenylephrine HCL 5mg + Bromhexine HCL 4mg +Menthol',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:571 ,
        name:'Dextromethorphan Hydrobromide 5mg + Phenylephrine HCL 5mg + Ambroxol HCL 15mg + Menthol 2.5mg ',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:572 ,
        name:'Dried Aluminium Hydroxide Gel 300mg + Magnisium hydroxide 150mg + Simethicone emulsion 125mg + Oxetacaine 10mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:573 ,
        name:'Dried Aluminium Hydroxide Gel 400mg + Magnisium hydroxide 400mg + Simethicone emulsion 50mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:574 ,
        name:'Lactolose concentrate10mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:575 ,
        name:'Levosalbutamol 0.5mg + Ambroxol Hydrochloride 15mg + Guaiphenesin 50mg + Mentholated Syrup Base',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:576 ,
        name:'Levosalbutamol 1mg + Ambroxol Hydrochloride 30mg + Guaiphenesin 50mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:577 ,
        name:'Magaldrate 400mg + Simethicone 20mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:578 ,
        name:'Magaldrate 400mg + Simethicone 40mg ',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:579 ,
        name:'Magaldrat 480mg+Simethicone 20mg +Domperidone 10mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:580 ,
        name:'Magaldrate 480mg + Domeperidone 10mg + Simethicone 20mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:581 ,
        name:'Magaldrate 480mg + Oxethazaine 10mg + Simethicone 60mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:582 ,
        name:'Magaldrate 480mg + Simethicone20mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:583 ,
        name:'Magaldrate 510mg + Simethicone 20mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:584 ,
        name:'Magaldrate 540mg + Activated Dimethicone50mg + Oxetacaine 10mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:585 ,
        name:'Mefenamic Acid 50mg + Paracetamol 125mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:586 ,
        name:'Montelukast 4mg + Levocitrizine 2.5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:587 ,
        name:'Norfloxacin 100mg + Tinidazole 75mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:588 ,
        name:'Oxetacaine 10mg + Aluminum Hydroxide 291mg + Magnesium Hydrochloride98mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:589 ,
        name:'Paracetamol 125mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:590 ,
        name:'Paracetamol 250mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:591 ,
        name:'Paracetamol IP 125mg + Promethazine Hydrochloride IP 5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:592 ,
        name:'Paracetamol 125mg + Promethazine HCL 5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:593 ,
        name:'Paracetamol 125mg + Cetirizine Hydrochloride 5mg + Phenylephrine Hydrochloride 2.5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:594 ,
        name:'Paracetamol 325mg + Domperidone 10mg suspension',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:595 ,
        name:'Paracetamol 125mg + Phenylephrine hydrochloride 2.5mg + Chlorpheniramine 1mg + Sodium citrate 60mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:596 ,
        name:'Paracetamol 125mg + Phenylephrine hydrochloride 2.5mg + Chlorpheniramine 1mg + Sodium citrate 60mg + Menthol 1mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
    },
    {
        id:597 ,
        name:'Piracetam 500mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:598 ,
        name:'Potassium Nitrate 3.0% w/v + Sodium Fluoride 0.2%w/v',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:599 ,
        name:'Potassium Citrate monohydrate 1100mg + Magnisium citrate 375mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:600 ,
        name:'Promethazine HCL 5mg + Acetaminophen 250mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:601 ,
        name:'Racecadrotril 10mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:602 ,
        name:'Risperdone 1mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:603 ,
        name:'Ranitidine Oral Susp',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:604 ,
        name:'Salbutamol sulphate 2mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:605 ,
        name:'Salbutamol sulphate 1mg +Bromhexine HCL 4mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:606 ,
        name:'Sodium Picosulphate 5mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:607 ,
        name:'Sucralfate 1gm + Oxetacaine 20mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids',
    },
    {
        id:608 ,
        name:'Terbutaline Sulphate 1.5mg +Bromhexine HCL 4mg + Guaiphenesin 50mg + Diphenhydramine HCL 8mg + Sodium Citrate 50mg +Menthol 1mg',    
        category:'Liquids/Syrup',
         subcategory:'Liquids/(Syrup/Susp.)',
         
    },

    // OINTMENT

    {
        id:609 ,
        name:'Aceclofenac 1.5% w/w + Linseed Oil 3.00% w/w + Methyl Salicylate 10%w/w + Capsaicin 0.025%w/w + Menthol 5%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:610 ,
        name:'Beclomethasone Dipropionate 0.025%w/w + Clotrimazole 1.00%w/w + Neomycin Sulphate 0.5%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:611 ,
        name:'Beclomethasone Dipropionate 0.025%w/w + Neomycin Sulphate 0.5%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:612 ,
        name:'Clobetasole Propionate 0.05%w/w + Miconazole Nitrate 2%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:613 ,
        name:'Clobetasole Propionate 0.05%w/w + Miconazole Nitrate 2%w/w + Neomycin Sulphate 0.5%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:614 ,
        name:'Clobetasole Propionate 0.05%w/w +Gentamycin Sulphate 0.2%w/w + Miconazole Nitrate 2.0%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:615 ,
        name:'Clotrimazole 1%w/w + Betamethasone Dipropionate 0.05%w/w + Gentamicin Sulphate 0.1%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:616 ,
        name:'Hydroquinone 2%w/w + Tretinoin 0.025%w/w + Mometasone Furoate 0.1%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:616 ,
        name:'Neomycin Sulphate 0.5%w/w + Clotrimazole 1.0%w/w + Chlorocresol 0.1%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:617 ,
        name:'Oleum Lini 3.0%w/w + Diclofenac Diethylamine 2.32%w/w + Methyl Salicylate 10.00%w/w + Menthol 5.00%w/w + Benzyl Alcohol 1.00% w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:618 ,
        name:'Oleum Lini 3.0%w/w + Diclofenac Diethylamine 1.16%w/w + Methyl Salicylate 10.00%w/w + Menthol 5.00%w/w + Benzyl Alcohol 1.00% w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:619 ,
        name:'Oleum Lini 3.0%w/w + Diclofenac Diethylamine 1.16%w/w + Capsaicin 0.025%w/w + Methyl Salicylate 10.00%w/w + Menthol 5.00%w/w + Benzyl Alcohol 1.00% w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:620 ,
        name:'Potassium Niotrate 5% + Sodium Monofluoro Phosphate 0.7%w/w + Triclosan 0.20%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:621 ,
        name:'Povidine Iodine 5.00%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:622 ,
        name:'Povidine Iodine 5.00%w/w +Metrodinazole 1.00%w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:623 ,
        name:'Pramoxine Hydrochloride Cream USP 1% w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
    },
    {
        id:624 ,
        name:'Pramoxine Hydrochloride Jelly USP 1% w/w',    
        category:'Nonbetalactam',
        subcategory:'Ointment',
        
    },

    // LIQUID INJECTION


    {
        id:625 ,
        name:'Each ml contains: Diclofenac Sodium IP 75 mg Benzyl Alcohol IP 4%w/v (as preservative)',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:626 ,
        name:'Each ml contains: Methylcobalamin IP 1500 mcg',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:627 ,
        name:'Benzyl Alcohol 1.5%v/v Water for injection q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:628 ,
        name:'Each 2 ml contains: Methylcobalamin IP 1000 mcg Pyridoxine Hydrochloride IP 100 mcg Niacotinamide IP 50 mg D-Panthenol IP 100 mg Benzyl Alcohol IP 1.5%w/v (as preservative) Water for Injection IP',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:629 ,
        name:'Each ml contains: Ondansetron Hydrochloride IP Eq. to. Ondansetron 2 mg Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:630 ,
        name:'Each 2ml contains: Ranitidine Hydrochloride IP Eq. to. Ranitidine 50 mg Phenol (as preservative) 0.5 % w/v (Suitably Buffered) Water for Injection q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:631 ,
        name:'Each ml contains: Clindamycin Phosphate IP Eq. to. Clindamycin 150 mg Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:632 ,
        name:'Each ml contains: Tranexamic Acid IP 100 mg Water for Injection IP q.s ',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:633 ,
        name:'Each ml contains : Citicoline Sodium IP Eq. to. Citicoline 250mg Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:634 ,
        name:'Each ml contains: Atropine Sulphate IP 0.6 mg Water for Injection IP q. s ',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:635 ,
        name:'Each ml contains: Atropine Sulphate IP 1 mg Water for Injection IP q. s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:636 ,
        name:'Each ml contains: Triamcinolone Acetonide IP 40 mg Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:637 ,
        name:'Each ml contains: Piroxicam IP 20 mg Benzyl Alcohol IP 20 mg (as preservative) Water for Injection IP',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:638 ,
        name:'Each ml contains: Frusemide IP 10 mg Water for Injection IP',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:639 ,
        name:'Each ml contains: Hyoscine Butylbromide IP 20 mg Sodium Methylparaben IP 0.18 w/v',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:640 ,
        name:'Each ml contains: Cholecalciferol (Vitamin D3) IP 600000 IU Ethyl Oleate IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:641 ,
        name:'Each ml contains: Cholecalciferol (Vitamin D3) IP 300000 IU Ethyl Oleate IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:642 ,
        name:'Each ml contains: Promethazine Hydrochloride IP 25 mg Phenol (as preservative) IP 05%w/v Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:643 ,
        name:'Each ml contains: Pheniramine Maleate IP 22.75 mg Methylparaben Sodium IP 0.135 %w/v Propylparaben Sodium IP 0.015%w/v Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:644 ,
        name:'Each ml contains : Meloxicam BP 5 mg Benzyl Alcohol IP 2% w/v (As preservative) Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:645 ,
        name:'Each ml contains : Oxetetracycline Hydrochloride',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:646 ,
        name:'Propylene glycol IP IP 50 mg Water for Injection IP q.s ',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:647 ,
        name:'Each ml contains : Ivermectin IP 10 mg Benzyl Alcohol IP 15% w/v (As Preservative) IP q.s Propylene Glycol q.s. Water for Injection',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:648 ,
        name:'Each ml contains : Atracurium Besylate IP 10 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:649 ,
        name:'Each ml contains : Bupivacaine Hydrochloride IP Eq. to Anhydrous Bupivacaine 5 mg Hydrochloride Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:650 ,
        name:'Each ml contains : Lignocaine Hydrochloride IP 20 mg Methylparaben IP 1 mg q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:651 ,
        name:'Each ml contains : Ligncocaine Hydrochloride IP 10 mg Methylparaben IP 1mg',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:652 ,
        name:'Each ml contains : Ketoralac Trmethamine IP 30 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:653 ,
        name:'Each ml contains : Ketoralac Tromethamine IP 15 mg Water for Injection IP q.s. ',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:654 ,
        name:'Each ml contains : Mephentermine Sulphate IP Eq. To Mephentermine 15 mg Methylparaben IP 0.15% w/v Propylparaben IP 0.02% w/v Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:655 ,
        name:'Each ml contains : IP Eq. to. Haloperidol 5 mg (In a Sesame Oil Vehicle) Benzyl Alcohol IP 0.6% w/v (As a Preservative) Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:656 ,
        name:'Each ml contains : Haloperidol Decanoate IP Eq. to. Haloperdol 10 mg (In a Sesame Oil Vehicle) Benzyl Alcohol IP 0.6% w/v (As a preservative) Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:657 ,
        name:'Each ml contains : Ascorbic Acid IP 250 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:658 ,
        name:'Each 2 ml contains : Ascorbic Acid IP 500 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:659 ,
        name:'Each ml contains : Zinc Chloride IP 1 mg Water for Injection IP q.s',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:660 ,
        name:'Each ml contains : Methylprednisolone Acetate IP 40 mg Benzyl Alcohol 2% v/v',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:661 ,
        name:'Each 0.6 ml contains : Enoxaparin Sodium IP 60 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:662 ,
        name:'Each 0.40 ml contains : Enoxaparin Sodium IP 40 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:663 ,
        name:'Each 0.2 ml contains : Enoxaparin Sodium IP 20 mg Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:664 ,
        name:'Each ml contains : Heparin Sodium IP 1000 IU Water for Injection IP q.s.',    
        category:'Injectable',
        subcategory:'Liquid Injection',
    },
    {
        id:665,
        name:'Cholecalciferol Soft Gelatin Capsules USP 60,000 IU',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:666,
        name:'Vitamin E So Gelan Capsules USP 400 mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:667,
        name:'Calcitriol 0.25 mcg + Calcium Carbonate  500 mg + Zinc 7.5 mg soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:668,
        name:'Calcitriol 0.25 mcg + Omega-3-fay Acids (EPA & DHA) 180 mg + Methylcobalamin + 1500 mcg Folic Acid + 400 mcg Boron + 1.5 mg Calcium Carbonate 500 mg  SoGelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:669,
        name:'Methylcobalamin IP 1500 mcg + Alpha Lipoic Acid USP 100mg + Pyridoxine Hydrochloride IP 3mg + Folic Acid IP 1.5mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:670,
        name:'Co Enzyme Q10 100mg + Omega-3 fay acid Containing + Eicosapentaenoic acid 90mg + Docosahexaenoic acid 60mg + Lycopene 10%  USP 4000mcg + Selenium dioxide monohydrate  70mcg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:671,
        name:'Ferrous Ascorbate(Dried) 100mg + Folic Acid IP 1.5mg + Zinc Sulphate Monohydrate IP 22.5mg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:672,
        name:'Methylcobalamin 1500mcg + Pyridoxal -5 Phosphate 0.5mg +  L-Methyl folate 1mg + and DHA 40% 200mg So gel Capsule',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:673,
        name:'Coral Calcium  225mg + Vitamin D3 200IU + Pyridoxin HCl IP 3mg + Folic Acid IP 1.5mg + Methylcobalamin 1500mcg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:674,
        name:'Evening Primrose Oil IP  100mg so gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:675,
        name:'Clindamycin Phosphate IP 10mg + Clotrimazole IP 200mg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:676,
        name:'Clindamycin Phosphate IP 100mg + Clotrimazole IP 100mg + Tinidazole IP 100mg so gelatincapsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:677,
        name:'Isotrenoin IP 20mg + soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:678,
        name:'Isotrenoin soft gelatin Capsules IP 100mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:679,
        name:' Levocerizine hydrochloride IP 5mg soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:680,
        name:'Dextromethorphan Hydrobromide 10mg + Chlorpheniramine Maleate 2mg & Phenylephrine Hydrochloride 5mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:681,
        name:'Diphenhydramine Hydrochloride IP 25 mg  Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:682,
        name:'Cetirizine hydrochloride IP 10 mg Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:683,
        name:'Simethicone IP 140mg  Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:684,
        name:'Sildenafil Citrate 100mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:685,
        name:'Mecobalamin IP 500mcg Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:686,
        name:'Sildenafil IP 50mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:687,
        name:'Vitamin A (as palmitate) IP 6000IU + Vitamin D3 IP IU Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:688,
        name:'Calcium carbonate IP 625mg & Alfacalcidol IP 0.25mg Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:689,
        name:'Lycopene 6% 2000mcg + vitamin A 2500IU + vitamin C 50mg +  Vitamin E 10IU + selenium 70mcg + zinc 27.45mg  Soft gelatin Capsules.',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:690,
        name:'Calcium Citrate Maleate 500mg  + Calcitriol 0.25mcg + Zinc 7.5mg & Magnesium 50mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:691,
        name:'Levocerizine IP 5mg + Montelukast Sodium IP 10mg Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:692,
        name:'Methylcobalamin IP 750mcg + Alpha Lipoic Acid USP 200mg + Pyridoxine Hydrochloride IP 1.5mg + Folic Acid IP 0.75mg  Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:693,
        name:'Methylcobalamin IP 1500mcg + Alpha Lipoic Acid USP 200mg + Pyridoxine Hydrochloride IP 3mg + Pyridoxine Hydrochloride IP 1.5mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:694,
        name:'Camphor USP 25 mg + Chlorothymol 5mg + Eucalyptus Oil USP 125mg + Terpineol BP 120mg + Menthol IP 55mg Soft gelatin Capsules',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:695,
        name:'Calcitriol IP 0.25mcg (25-Dihydroxy vitamin D3) + Elemental calcium 200mg Soft gelatin Capsules ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:696,
        name:'Omega -3-fay acid containing 33:22 + Eicopentaenoic Acid (EPA) 90mg + Docosahexaenoic acid (DHA) 60mg + Calcium ascorbate USP45mg + Ferrous fumarate IP 30mg + Magnesium oxide IP 30mg + Green tea extract 10mg + Ginseng extract USP 42.5mg + Ginkgo biloba extract 10mg + Citrus bioflavonoids 220mg + Natural mixed carotenoids 5.665mg + Potassium chloride IP 4mg + Potassium 3.6mg + Silicon 2mg + Manganese sulphate USP Manganese 1.5mg + Vitamin D3 IP 200IU + Wheat germ oil BP 25mg +  Benfotiamine 1.5mg + . Copper 0.5mg + Vitamin B6 IP. 1mg + Vitamin B12 IP 1mcg + Folic acid IP 0.15mg +  Bion USP 100mcg + Choline Bitartrate USP 25 mg + Lutein USP 250mcg + Piperine 5mg +  Calcium 20mg + Phosphorous 15.45mg + Zinc oxide IP 15mg + Boron 150mcg + Chromium 65mcg + Molybdenum25mcg +  . Selenium 20mcg + Vanadium 10mcg + Nickel 5mcg + Lactic acid 500lakh  ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },

    {
        id:976,
        name:'Eicosapentaenoic acid (EPA) 90mg + Docosahexaenoic acid (DHA) 60mg + Green tea extract eq. to polyphenols 10mg + Ginseng extract USP 42.5mg + Ginkgo biloba extract USP 10mg + Grape seed extract 15 mg + Lacc acid bacillus 500lacs + 8 mg of bioflavonoids 20mg + Natural mixed carotenoids (10%) 11.3 mg + Vitamin D3 IP 200IU + Wheat germ oil 25mg + Menadione sodium bisulfite (vit.K3) 10mcg + Benfotiamine 1.5mg + Vitamin B6 IP 1mg + Vitamin B12 IP 1mcg + Niacinamide IP 20mg + Calcium ascorbate USP 45mg + Folic acid IP 150mcg + D- bion USP 100mcg + Choline Bitartrate USP 25mg + Lutein USP 250mcg + Dibasic calcium phosphate IP Calcium 20mg + Phosphorous 15.45mg + Ferrous fumarate IP 30mg +  Zinc oxide IP Zinc 15mg + iodine 150mcg + Magnesium 30mg + Manganese 1.5mg + Copper 0.5mg + Copper 65mcg + Molybdenum 25mcg + Selenium 20mcg + potassium 4mg +  Chloride 3.6mg + Boron 150mcg + Silicon 2mg + Nickel 5mcg + . Vanadium 10mcg + Piperine 5mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
    {
        id:677,
        name:'Calcitriol IP 0.25mcg + Calcium carbonate IP 500mg + Zinc 7.5mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },

    {
        id:678 ,
        name:'Vitamin E(As DL-Alpha Tocopherol Acetate)Ip 100mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:679,
        name:'Levocerizine Dihydrochloride IP…. 10mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:680,
        name:'Methylcobalamin IP 500mcg + Alpha Lipoic Acid USP 100mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:681,
        name:'Methylcobalamin IP 500mcg + Alpha Lipoic Acid USP 100mg + Pyridoxine Hydrochloride IP 1mg + Folic Acid IP 0.5mg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:682 ,
        name:'Beta-carotene(30% Dispersion) USP 10 mg + Selenium Dioxide Monohydrate USP 70 mcg +  Manganese(Manganese Sulphate) BP 2mg + Zinc Sulphate Monohydrate IP (Zinc 27.5 ) Copper (Copper Sulphate Pentahydrate)B.P1mg) ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:683,
        name:'Methylcobalamin IP 1500mcg + Alpha Lipoic Acid USP 100mg + Benfotiamine 15mg + Vitamin B6 IP 3mg + Calcium Pantothenate IP 25mg + Folic Acid IP 1.5mg + Zinc Oxide IP 22.5mg + Chromium Picolinate USP 65mcg + Lutein 8% USP 5mg + Inositol USP 10mcgh',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:684,
        name:'Inositol USP 0.25mcg + Calcium Citrate Maleate 500mg + Vitamin k2-7 50mcg + Zinc Oxide IP 7.5mg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:685,
        name:'Methylcobalamin IP 500mcg + Beta-carotene (30% Dispersion) USP 10.33mg + Alpha Lipoic Acid USP 100mg + Vitamin B6 IP 1.5mg + Vitamin B1 IP 2mg + Chromium Picolinate USP 70mcg + ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:686,
        name:'Methylcobalamin IP  500mcg + Pregabalin IP 75mg + Methylcobalamin IP 500mcg + Pregabalin IP 150mg + Cholecalciferol (Vitamin D3) IP 2000IU ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:687,
        name:'Beta Carotene (derived from naturally occurring mixed carotenoids) 30mg + Zinc Sulphate Monohydrate IP. 27.5mg + Selenium Dioxide 200mcg + Manganese BP 2mg + Copper 1mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:688,
        name:'Calcium carbonate I.P. 200mg + Calcitriol B.P 0.25mcg + Methylcobalamin 1500mcg + Folic acid I.P 1.5mg + Vitamin B6 I.P 3mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:687,
        name:'L-Methylfolate 1mg + Methylecobalamin 1500mcg + pyridoxal-5-Phosphate 0.5mg + omega -3-fish oil eq. to Eicosapentaenoic Acid 90mg + Docosahexaenoic Acid 60mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:688,
        name:'Alfacalcidol BP 0.25mcg + Calcium carbonate (from oyster shell) Eq. to elemental calcium 200mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:689,
        name:'Calcitriol IP 0.25mcg + Calcium Citrate USP 425mg + Zinc Oxide IP 200mg + Magnesium Oxide IP 40mg  ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:690,
        name:'Silymarin 140mg + Lecithin USP 525mg + Phosphadylserine 262.5mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:691,
        name:'Ferric Ammonium Citrate IP 160mg + Vitamin B12 IP 7.5mcg + Folic Acid IP 0.5mg + Zinc Sulphate Monohydrate USP 20.61mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:692,
        name:'Calcitriol I.P. 0.25mcg  + Calcium carbonate IP. 500mg + Zinc IP. 7.5mg + Magnesium IP. 50mg + Vitamin K27 IP 45mcg + Methylcobalamin IP 1500mcg + L-methylfolate IP. 800mcg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:693,
        name:'Dried Ferrous Sulphate IP 200 mg + Copper sulphate Pentahydrate BP 2.5mg + Manganese sulphate mono hydrate BP 2.5 mg + Folic acid IP 1 mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:694,
        name:'Calcium Carbonate IP 250mg + Calcitriol IP 0.25mcg +  Vitamin-K27 45mcg + Methylcobalamin IP 750mcg + Magnesium Oxide IP Magnesium 50mg + Boron 1.5mg + Zinc Oxide IP 7.5mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:695,
        name:'Calcitrion IP 0.25mcg + Calcium Carbonate IP 1250mg + Vitamin-K27 Methylcobalamin IP 1500mcg + Zinc 7.5mg + Magnesium Hydroxide IP Magnesium 50mg + L-Methylfolate 800mcg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:696,
        name:'Calcium Citrate Maleate (elemental Calcium 200mg) + Calcitriol IP 0.25mg + Methylcobalamin IP 1500mcg + Folic Acid IP 1.5mg + Pyridoxine IP 3mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:697,
        name:'L-Arginine 100mg + Co-enzyme Q10(Ubidecarenone) 50mg + Chaste-Berry Extract 50mg + Acetylcysteine USP 100mg + Inositol BP 100mg + Folic Acid IP 100mcg + Zinc Sulphate Monohydrate IP 15mg + Omega-3 Fattey Acid BP 100mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:698,
        name:'Alph Lipoic Acid USP 50mg + Omega-3 Fatty Acids providing Eicosapentaenoic Acid 45mg + Docosahexaenoic  Acid (DHA) 30mg + Mixed Carotenoids  USP 10mg + Zinc 7.5mg + Lycopen 10% USP 25mg + Manganese 1.5mg + Copper 1mg + Chromium (as Chromium Picolinate USP) 1mg + Chromium (as Chromium Picolinate USP) 200mcg + Selenium (as Selenium Acid USP) 150mcg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:699,
        name:'L-Arginine USP 25mg + Vitamin-E Acetate IP 25mg + Betacarotene(30% dispersion) USP 6mg + Lycopene 6% USP 10mg + Zinc Oxide IP eq. to elemental Zinc 7.5mg + Lutein 8% USP 7mg + Selenium 100mcg + Manganese Sulphate USP 100mcg + Copper Sulphate BP 1mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:700,
        name:'Lutein 8%  USP 3.2mg + Vitamin-E Acetate IP 25mg + Betacarotene(30% dispersion) USP 6mg + Lycopene 6% USP 10mg + Zinc Oxide IP 7.5mg + Lutein 8% USP 7mg + Selenium from Selenium Dioxide USP 100mcg + Manganese 1mg + Copper 1mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:701,
        name:'Docosahexaenoic Acid (DHA) 40% 200mg + L-Methylfolate 1mg + Pyridoxal-5 Phospate 0.5mg + Methylcobalamin IP 1500mcg + Vitamin-D3 IP 2000IU ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:702,
        name:'Calcium Carbonate IP 6225mg + Calcitriol IP 0.25mcg + Vitamin K2-7 45mcg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:703,
        name:'Lycopen 10% 10,000mcg + D L Methionine BP 25mg + Grape Seed Extract 10mg + Adenosylcobalamin 250mcg + Lutein 10% 3mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:704,
        name:'Calcium Citrate Maleate 500mg + Magnesium Hydroxide IP 100mg + Zinc Sulphate Monohydrate IP 4mg + Vitamin-D3 IP 200IU',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:705,
        name:'Methylcobalamin IP 1500mcg + Alpha Lipoic Acid USP 100mg + Folic Acid IP 1.5mg + Seleninum Dioxide Benfotiamine 150mg + Inositol USP 100mcg + Chromium Picolinate 200mcg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:706,
        name:'Grape Seed Extract 25mg + Lycopene 6% USP 2mg + Lutein 8% USP 3mg + Vitamin-A IP 5000IU + Vitamin-B1 IP 5mg + Vitamin-B2 IP 5mg + Vitamin-B6 IP 1.5mg + Vitamin-B12 IP 15mcg + Folic Acid IP 1.5mg + Zinc Sulphate Monohydrate IP 23mg + Selenium Dioxide Monohydrate USP 75mcg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:707,
        name:'Methylcobalamin IP 1500mcg + Alpha Lipoic Acid USP 100mg +  Folic Acid IP 1.5mg +  Pyridoxine Hydrochloride IP 3mg +  Vitamin-D3 IP 1000IU',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:708,
        name:'L-Carnitine 500mg + Coenzyme-Q10 USP 100mg + Vitamin-K27 50mcg + Astaxanthin 8mg + Magnesium Sulphate IP 50mg + Zinc Sulphate Monohydrate IP 7.5mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:709,
        name:'Curcuma Longa 95% 600mg + Boswellia Serrata Extract 250mg + Piperine 5mg',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:710,
        name:'Evening Primrose Oil 1000mg + Tocotrienol 50% 30mg + L-Methylfolate 400mcg + Methylcobalamin IP 1500mcg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
    {
        id:711,
        name:'Omega-3 Fay Acids Providing Eicosapentaenoic Acid (EPA) 90mg + Docosahexaenoic Acid (DHA) 60mg + L-Methylfolate 1mg + Vitamin-K27  45mcg +  Methylocbalamin IP 1500mcg + Pyridoxal-5 Phosphate 0.5mg + Lycopene 6%  10,000mcg + Calcium Citrate USP 500mcg +  Calcitriol IP 0.25mcg + Magnesium Sulphate IP 50mg ',
        category:'Softgel Capsules',
        subcategory:'Capsules',
    },
     
];

export default Productdata;