import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footer_flexbox}>

                    <div className={styles.footer_box}>
                        <h3>Registered office</h3>
                        <div className={styles.footer_content}>
                            <div className={styles.address}>
                                <i class="fa-solid fa-location-dot"></i>
                                <p>Office No:- 206 VTP trade Park Katraj Hadpasar road Undri Pune, Maharashtra.</p>
                            </div>

                        </div>

                        <div className={styles.footer_content}>
                            <div className={styles.footer_contact_info}>
                                <i class="fa-solid fa-mobile"></i>
                                <p>Mobile : <a href='tel:+91 9730100676'>+91 9730100676</a></p>
                            </div>
                        </div>

                        <div className={styles.footer_content}>
                            <div className={styles.footer_contact_info}>
                                <i class="fa-solid fa-envelope"></i>
                                <p>Email:<a href='mailto:info@bjshealthcare.com'> info@bjshealthcare.com</a></p>
                            </div>

                        </div>
                    </div>


                    <div className={styles.footer_box}>
                        <h3>Useful Links</h3>
                        <div className={styles.footer_content}>
                            <ul>
                                <Link to='/' className={styles.footer_links}>Home</Link>
                                <Link to='/About' className={styles.footer_links}>About Us</Link>
                                <Link to='/Manufacturing' className={styles.footer_links}>Manufacturing</Link>
                                <Link to='/Products' className={styles.footer_links}>Products</Link>
                                <Link to='/Blog' className={styles.footer_links}>Blog</Link>
                                <Link to='/Contact' className={styles.footer_links}>Contact Us</Link>
                            </ul>
                        </div>
                    </div>

                    <div className={styles.footer_box}>
                        <h3>Newsletter</h3>
                        <div className={styles.footer_content}>
                            <p>Signup for our newsletter to get the latest news, updates and special offers in your inbox.</p>
                        </div>

                        <div className={styles.footer_content}>
                            <input type='email' placeholder='Email' required />
                            <button type='submit' className={styles.footer_button}><i class="fa-solid fa-arrow-right"></i></button>
                        </div>

                        <div className={styles.footer_content}>
                            <p style={{ fontSize: '12px' }}>Your email is safe with us. We don't spam.</p>
                        </div>
                        <div className={styles.footer_content}>
                            <div className={styles.footer_icons}>
                            <i class="fa fa-facebook"></i>
                            <i class="fa fa-linkedin"></i>
                                <i class="fa-brands fa-instagram"></i>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div className={styles.footer_bottom}>
                <div className={styles.footer_bottom_inner}>
                    <p>© Copyright 2024 -BJS Healthcare</p>
                    <a href='https://bjshealthcare.in/'>Designed by:
                    BJS Healthcare</a>
                </div>

            </div>
        </>
    )
}

export default Footer