import React from 'react'
import styles from './Contact.module.css'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask';
import heroStyles from '../Home/Mainhome/Mainhome.module.css'
const Contact = () => {
    const googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query=206+VTP+Trade+Park+Katraj+Hadapsar+Road+Undri+Pune';

    return (
        <>
            <div className={styles.contact}>
                <div className={styles.contact_hero}>
                    <div className={styles.contact_hero_content}>
                        <h2>Contact Us</h2>
                        <div className={styles.breadcomb}>
                            <Link to='/' className={styles.breadcomb_link}>Home</Link>{'>>'}  <Link to='/Contact' className={styles.breadcomb_link}>Contact</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.contact_main}>
                    <div className={styles.contact_main_header}>

                        <div className={styles.contact_header_icons}>
                            <i className="fa-solid fa-location-dot"></i>
                            <p><a
                                href={googleMapsUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.locationLink}
                            >
                                Office No: 206 VTP Trade Park, Katraj Hadapsar Road, Undri, Pune
                            </a></p>
                        </div>

                        <div className={styles.contact_header_icons}>
                            <i class="fa-solid fa-phone"></i>
                            <p><a href='tel:+919730100676' >Mobile : +919730100676</a></p>
                        </div>

                        <div className={styles.contact_header_icons}>
                            <i class="fa-solid fa-envelope"></i>
                            <p> <a href='mailto:info@bjshealthcare.com'>info@bjshealthcare.com</a></p>
                        </div>

                    </div>
                    <div className={styles.contact_main_flexbox}>
                        <div className={heroStyles.contact_form}>
                            <div className={heroStyles.home_contact}>
                                <div className={heroStyles.home_contact_main}>
                                    <div className={heroStyles.contact_form}>
                                        <h2>Contact Us</h2>
                                        <p>We will resolve your inquiry within 24 hours.</p>
                                        <div className={heroStyles.contact_form_main}>
                                            <form>
                                                <div className={heroStyles.inputs}>
                                                    <input type='text' name='fullname' placeholder='Full Name*' required />
                                                    <input type='email' name='email' placeholder='Email*' required />
                                                </div>

                                                <div className={heroStyles.inputs}>
                                                    <InputMask
                                                        mask="+91 99999 99999"
                                                        maskChar=""
                                                        pattern="\+91\s\d{5}\s\d{5}"
                                                        required
                                                    >
                                                        {(inputProps) => (
                                                            <input
                                                                {...inputProps}
                                                                type="tel"
                                                                name="phonenumber"
                                                                placeholder="Phone no*"
                                                            />
                                                        )}
                                                    </InputMask>
                                                    <input type='text' name='city' placeholder='City*' required />
                                                </div>
                                                <div className={heroStyles.inputs}>
                                                    <textarea name='message' placeholder='Message*' required />
                                                </div>

                                                <button type='submit' className={heroStyles.readmore}>
                                                    <span className={heroStyles.links}>Submit Now</span>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Location Map */}
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.024817314033!2d73.9128384!3d18.4493752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb47aceaffc3%3A0xb5f50ebfee8fc0a8!2sBJS%20HEALTHCARE%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1723107397205!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps Location"
                    ></iframe>
                </div>
            </div>
        </>
    )
}

export default Contact