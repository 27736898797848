import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Mainhome from './Components/Home/Mainhome/Mainhome'
import Footer from './Components/Footer/Footer'
import About from './Components/About/About'
import Manufacturing from './Components/Manufacturing/Manufacturing'
import Products from './Components/Products/Products'
import Contact from './Components/Contact/Contact'
import ScrollToTop from './Components/ScrollToTop'
import logo from './Assets/Logo.png'
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import Blog from './Components/Blog/Blog'

const App = () => {
  return (
    <>
      <BrowserRouter basename='/' > {/* basename='/BJS' */}
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Mainhome />} />
          <Route path='/About' element={<About />} />
          <Route path='/Manufacturing' element={<Manufacturing />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Blog' element={<Blog />} />
        </Routes>
        <WhatsAppWidget
          phoneNo='+919730100676'
          companyName='BJS Healthcare'
          headerIcon={logo}
          position="left"
          messageBox={true}
          autoOpen={false}
          autoOpenTimer={5000}
          widgetWidthMobile="260px"
          headerTitle="BJS Healthcare"
          chatPersonName="BJS Healthcare"
        />
        <Footer />
      </BrowserRouter>

    </>
  )
}

export default App