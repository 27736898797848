import c1 from '../../Assets/Clients/1.jpg'
import c2 from '../../Assets/Clients/2.jpg'
import c3 from '../../Assets/Clients/3.jpg'
import c4 from '../../Assets/Clients/4.jpg'
import c5 from '../../Assets/Clients/5.jpg'
import c6 from '../../Assets/Clients/6.jpg'
import c7 from '../../Assets/Clients/7.jpg'
import c8 from '../../Assets/Clients/8.jpg'
import c9 from '../../Assets/Clients/9.jpg'
import c10 from '../../Assets/Clients/10.jpg'
import c11 from '../../Assets/Clients/11.jpg'
import c12 from '../../Assets/Clients/12.jpg'
import c13 from '../../Assets/Clients/13.jpg'
import c14 from '../../Assets/Clients/14.jpg'
import c15 from '../../Assets/Clients/15.jpg'
import c16 from '../../Assets/Clients/16.jpg'
import c17 from '../../Assets/Clients/17.jpg'
import c18 from '../../Assets/Clients/18.jpg'
import c19 from '../../Assets/Clients/19.jpg'
import c20 from '../../Assets/Clients/20.jpg'
import c21 from '../../Assets/Clients/21.jpg'
import c22 from '../../Assets/Clients/22.jpg'
import c23 from '../../Assets/Clients/23.jpg'
import c24 from '../../Assets/Clients/24.jpg'
import c25 from '../../Assets/Clients/25.jpg'
import c26 from '../../Assets/Clients/26.jpg'
import c27 from '../../Assets/Clients/27.jpg'
import c28 from '../../Assets/Clients/28.jpg'
import c29 from '../../Assets/Clients/29.jpg'
import c30 from '../../Assets/Clients/30.jpg'
import c31 from '../../Assets/Clients/31.jpg'
import c32 from '../../Assets/Clients/32.jpg'
import c33 from '../../Assets/Clients/33.jpg'
import c34 from '../../Assets/Clients/34.jpg'


const Clientdata = [
    {id:1, image:c1},
    {id:2, image:c2},
    {id:3, image:c3},
    {id:4, image:c4},
    {id:5, image:c5},
    {id:6, image:c6},
    {id:7, image:c7},
    {id:8, image:c8},
    {id:9, image:c9},
    {id:10, image:c10},
    {id:11, image:c11},
    {id:12, image:c12},
    {id:13, image:c13},
    {id:14, image:c14},
    {id:15, image:c15},
    {id:16, image:c16},
    {id:17, image:c17},
    {id:18, image:c18},
    {id:19, image:c19},
    {id:20, image:c20},
    {id:21, image:c21},
    {id:22, image:c22},
    {id:23, image:c23},
    {id:24, image:c24},
    {id:25, image:c25},
    {id:26, image:c26},
    {id:27, image:c27},
    {id:28, image:c28},
    {id:29, image:c29},
    {id:30, image:c30},
    {id:31, image:c31},
    {id:32, image:c32},
    {id:33, image:c33},
    {id:34, image:c34},

];
export default Clientdata;