import React, { useEffect, useRef } from 'react'
import styles from './Manufacturing.module.css'
import { Link } from 'react-router-dom'

// Import images (you'll need to add these to your project)
import manufacturingProcess from '../../Assets/Manufacturing/manufacturing-process.jpg'
import qualityControl from '../../Assets/Manufacturing/quality-control.jpg'
import researchDevelopment from '../../Assets/Manufacturing/research-development.jpg'

const Manufacturing = () => {
    const contentRef = useRef(null);
    const processRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.animate);
                    }
                });
            },
            { threshold: 0.1 }
        );

        [contentRef, processRef, contactRef].forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => observer.disconnect();
    }, []);

    return (
        <div className={styles.manufacturing}>
            <div className={styles.manufacturing_hero}>
                <div className={styles.manufacturing_hero_content}>
                    <h1>Manufacturing</h1>
                    <div className={styles.breadcrumb}>
                        <Link to='/' className={styles.breadcrumb_link}>Home</Link>
                        <span className={styles.breadcrumb_separator}>&gt;</span>
                        <Link to='/Manufacturing' className={styles.breadcrumb_link}>Manufacturing</Link>
                    </div>
                </div>
            </div>

            <div className={styles.manufacturing_container}>
                <div ref={contentRef} className={`${styles.manufacturing_content} ${styles.fadeIn}`}>
                    <h2>Third Party Manufacturing Pharma Company</h2>
                    <p>BJS Healthcare PVT. LTD. is a fastest emerging well-known company, committed to delivering quality products since 2010. Since 2016, we have been extensively engaged in the manufacturing of pharmaceutical formulations. We have a state-of-the-art manufacturing facility in Baddi (Himachal Pradesh) with major expansion plans to cater to the international market.</p>
                    
                    <div className={styles.image_text_section}>
                        <img src={manufacturingProcess} alt="Manufacturing Process" className={styles.section_image} />
                        <div>
                            <h3>Our Focus</h3>
                            <p>Our main focus is to produce cost-effective, high-quality, and secure solutions for our customers. To accomplish this goal, we utilize the best technology, methods, and personnel. Our innovative approaches, ideas, and outstanding client assistance at every step are the intrigue behind the prestige of our brand.</p>
                        </div>
                    </div>

                    <div className={styles.image_text_section}>
                        <div>
                            <h3>Quality Control</h3>
                            <p>We own an extreme infrastructure that is set up with the most advanced concepts for convenient delivery of our developed quality drugs. The quality control laboratory, packaging, and shipping units are devised to regulate the goals and aspirations, keeping in mind the standards of the Industry.</p>
                        </div>
                        <img src={qualityControl} alt="Quality Control" className={styles.section_image} />
                    </div>

                    <div className={styles.image_text_section}>
                        <img src={researchDevelopment} alt="Research and Development" className={styles.section_image} />
                        <div>
                            <h3>Research and Development</h3>
                            <p>Our infrastructure also comprises a research and development center where we carry out different researches to ensure that we deliver optimum quality products to our clients. This commitment to innovation keeps us at the forefront of the pharmaceutical industry.</p>
                        </div>
                    </div>
                </div>

                <div ref={processRef} className={`${styles.manufacturing_process} ${styles.fadeIn}`}>
                    <h2>Our Manufacturing Capabilities</h2>
                    <p>The manufacturing unit has been established at Baddi (Distt. Solan) Himachal Pradesh by the name of BJS Healthcare PVT LTD. This unit has three sections to manufacture products in various forms:</p>
                    <div className={styles.product_categories}>
                        <div className={styles.category}>
                            <h4>Pain Management</h4>
                            <ul>
                                <li>Painkiller</li>
                                <li>Orthopaedic</li>
                            </ul>
                        </div>
                        <div className={styles.category}>
                            <h4>Specialized Formulations</h4>
                            <ul>
                                <li>SoftGels</li>
                                <li>Liquids</li>
                                <li>Nasal Drops</li>
                            </ul>
                        </div>
                        <div className={styles.category}>
                            <h4>Therapeutic Areas</h4>
                            <ul>
                                <li>Anti Diabetic</li>
                                <li>Cardiac Range</li>
                                <li>Gynaecology</li>
                                <li>PCOD</li>
                                <li>Pregnancy</li>
                                <li>Paediatric</li>
                                <li>Gastrology</li>
                                <li>Dermatology</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div ref={contactRef} className={`${styles.contact_info} ${styles.fadeIn}`}>
                    <h2>Contact Information</h2>
                    <div className={styles.contact_details}>
                        <div className={styles.contact_item}>
                            <h3>Name</h3>
                            <p>BJS Healthcare</p>
                        </div>
                        <div className={styles.contact_item}>
                            <h3>Address</h3>
                            <p>Office No:- 206 VTP trade Park Katraj Hadpasar road Undri Pune</p>
                        </div>
                        <div className={styles.contact_item}>
                            <h3>Phone Number</h3>
                            <p>+91 9730100676</p>
                        </div>
                        <div className={styles.contact_item}>
                            <h3>Mail ID</h3>
                            <p>info@bjshealthcare.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Manufacturing