import React, { useEffect, useRef } from 'react'
import styles from './About.module.css'
import { Link } from 'react-router-dom'
import Aboutbanner from '../../Assets/About/about_content_banner.png'
import mission from '../../Assets/About/mission.jpg'
import vision from '../../Assets/About/vision.jpg'
import values from '../../Assets/About/values.jpg'
import Quality from '../../Assets/About/Quality.jpg'

const About = () => {
    const aboutRef = useRef(null);
    const bannerRef = useRef(null);
    const whyChooseUsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.animate);
                    }
                });
            },
            { threshold: 0.1 }
        );

        [aboutRef, bannerRef, whyChooseUsRef].forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => observer.disconnect();
    }, []);

    return (
        <div className={styles.about}>
            <div className={styles.about_hero}>
                <div className={styles.about_hero_content}>
                    <h2>About</h2>
                    <div className={styles.breadcrumb}>
                        <Link to='/' className={styles.breadcrumb_link}>Home</Link>
                        <span className={styles.breadcrumb_separator}>&gt;</span>
                        <Link to='/About' className={styles.breadcrumb_link}>About</Link>
                    </div>
                </div>
            </div>

            <div ref={aboutRef} className={`${styles.about_main_content} ${styles.fadeIn}`}>
                <div className={styles.about_container}>
                    <h2>About Us | BJS Healthcare</h2>
                    <p>BJS Healthcare PVT. LTD. is a fastest emerging well-known company, committed to delivering quality products since 2010. Since 2016, we have been extensively engaged in the manufacturing of pharmaceutical formulations. We have a state-of-the-art manufacturing facility in Baddi (Himachal Pradesh). We have major expansion plans for the future with a view to cater to the international market.</p>
                    <p>BJS Healthcare PVT. LTD. is the best pharma manufacturing company which has earned many laurels from years of hard work. Within a short span, BJS Healthcare PVT. LTD. is at the forefront of contract manufacturing with India's leading Pharma Houses.</p>
                    <p>Our main focus is to produce cost-effective, high-quality, and secure solutions for customers. To accomplish this goal, we make use of the best technology, methods, and personalities. Our innovative approaches and ideas, and moreover our outstanding client assistance at every step, is the intrigue behind the prestige of our brand. We work jointly with our clients to understand and identify their needs and expectations. Apart from this, we also have self-assessment through a voluntary self-audit of our operations in order to remain competitive in the market.</p>
                </div>
            </div>

            <div ref={bannerRef} className={`${styles.about_main_banner} ${styles.fadeIn}`}>
                <img src={Aboutbanner} alt='BJS Healthcare About' />
            </div>

            <div ref={whyChooseUsRef} className={`${styles.about_why_chooseus} ${styles.fadeIn}`}>
                <div className={styles.why_chooseus}>
                    <div className={styles.why_chooseus_heading}>
                        <h3>Why Choose Us</h3>
                        <h2>Our Mission, Vision & Values</h2>
                    </div>

                    <div className={styles.why_chooseus_grid}>
                        <div className={`${styles.why_grid_item} ${styles.mission}`}>
                            <img src={mission} alt='BJS Healthcare Vision' />
                            <h2>Our Mission</h2>
                            <p>To be a leading global pharmaceutical company, dedicated to improving the quality of life by providing innovative and affordable healthcare solutions.</p>
                        </div>

                        <div className={`${styles.why_grid_item} ${styles.vision}`}>
                        <img src={vision} alt='BJS Healthcare Vision' />
                            <h2>Our Vision</h2>
                            <p>To be a leading global pharmaceutical company, dedicated to improving the quality of life by providing innovative and affordable healthcare solutions.</p>
                        </div>

                        <div className={`${styles.why_grid_item} ${styles.values}`}>
                        <img src={values} alt='BJS Healthcare Vision' />
                            <h2>Our Values</h2>
                            <p>We are committed to integrity, excellence, and respect for all individuals, fostering an environment of collaboration and continuous improvement in everything we do.</p>
                        </div>

                        <div className={`${styles.why_grid_item} ${styles.quality}`}>
                            <img src={Quality} alt='BJS Healthcare Quality' />
                            <h2>Quality</h2>
                            <p>We adhere to the highest standards of quality, ensuring our products are safe, effective, and reliable, meeting stringent regulatory requirements and customer expectations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About