import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';
import styles from './Hero.module.css';

import slide1 from '../../Assets/Home/slide1.png';
import slide2 from '../../Assets/Home/slide2.png';
import slide3 from '../../Assets/Home/slide3.png';

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className={styles.hero} role="banner" aria-label="Hero Slider">
      <Slider {...settings}>
        <div>
          <img
            src={slide1}
            alt='High-quality healthcare solutions from BJS Healthcare'
            className={styles.slideImage}
            loading="lazy"
          />
        </div>
        <div>
          <img
            src={slide2}
            alt='Innovative medical products by BJS Healthcare'
            className={styles.slideImage}
            loading="lazy"
          />
        </div>
        <div>
          <img
            src={slide3}
            alt='Reliable healthcare solutions from BJS Healthcare'
            className={styles.slideImage}
            loading="lazy"
          />
        </div>
      </Slider>
    </div>
  );
}

export default Hero;
