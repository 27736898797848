import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './Products.module.css';
import Painkiller from '../../Assets/Products/painkiller.png';
import Softgel from '../../Assets/Products/Softgel.png';
import Syrup from '../../Assets/Products/syrup.png';
import Nasal_drop from '../../Assets/Products/nasal_drop.png';
import Anti_diabetic from '../../Assets/Products/Anti_diabetic.png';
import Cardiac from '../../Assets/Products/Cardiac.png';
import Gynaecology from '../../Assets/Products/Gynaecology.png';
import Paediatric from '../../Assets/Products/Paediatric.png';
import Orthopedic from '../../Assets/Products/Orthopedic.png';
import Gastrology from '../../Assets/Products/Gastrology.png';
import Productdata from './productdata';

const Products = () => {
    const categories = ['All', 'Betalactam', 'Nonbetalactam', 'Softgel Capsules', 'Liquids/Syrup', 'Injectable'];
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(Productdata);
    const suggestionsRef = useRef(null);

    useEffect(() => {
        filterProducts();
    }, [selectedCategory, searchQuery]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
            setSuggestions([]);
        }
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSearchQuery('');
        setSuggestions([]);
    };

    const handleSearchInput = (event) => {
        const query = event.target.value.trim();
        setSearchQuery(query);
        if (query !== '') {
            const filteredSuggestions = Productdata.filter(product =>
                product.name.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions.slice(0, 5).map(product => product.name));
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchQuery(suggestion);
        setSuggestions([]);
        const product = Productdata.find(p => p.name === suggestion);
        if (product) {
            setSelectedCategory(product.category);
        }
    };

    const filterProducts = () => {
        let filtered = Productdata;
        if (selectedCategory !== 'All') {
            filtered = filtered.filter(product => {
                if (selectedCategory === 'Softgel Capsules') {
                    return product.category.toLowerCase() === 'softgel capsules';
                }
                return product.category === selectedCategory;
            });
        }
        if (searchQuery) {
            filtered = filtered.filter(product =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        setFilteredProducts(filtered);
    };

    const productIcons = [
        { name: 'Painkiller', image: Painkiller },
        { name: 'Softgel', image: Softgel },
        { name: 'Syrup', image: Syrup },
        { name: 'Nasal drop', image: Nasal_drop },
        { name: 'Anti diabetic', image: Anti_diabetic },
        { name: 'Cardiac', image: Cardiac },
        { name: 'Gynaecology', image: Gynaecology },
        { name: 'Paediatric', image: Paediatric },
        { name: 'Orthopedic', image: Orthopedic },
        { name: 'Gastrology', image: Gastrology },
    ];

    return (
        <div className={styles.products}>
            <h1 className={styles.title}>Our Product Range</h1>
            <div className={styles.products_range_icons}>
                {productIcons.map((icon, index) => (
                    <div key={index} className={`${styles.icons} ${styles[icon.name.toLowerCase().replace(' ', '_')]}`}>
                        <img src={icon.image} alt={`BJS Healthcare ${icon.name} Manufacturing`} />
                        <p>{icon.name}</p>
                    </div>
                ))}
            </div>
            <div className={styles.product_list}>
                <div className={styles.product_list_top}>
                    <div className={styles.product_buttons}>
                        {categories.map((category, index) => (
                            <button
                                key={index}
                                className={`${styles.category_button} ${selectedCategory === category ? styles.active : ''}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                    <div className={styles.search_container}>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchInput}
                            placeholder="Search products..."
                            className={styles.search_input}
                        />
                        {suggestions.length > 0 && (
                            <div ref={suggestionsRef} className={styles.suggestions_container}>
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className={styles.suggestion_item}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.product_list_main}>
                    {filteredProducts.length > 0 ? (
                        <table className={styles.product_table}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Subcategory</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map((product) => (
                                    <tr key={product.id}>
                                        <td>{product.name}</td>
                                        <td>{product.category}</td>
                                        <td>{product.subcategory}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className={styles.no_results}>No products found.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Products;