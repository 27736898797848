import React, { useState } from 'react'
import styles from './Navbar.module.css'
import logo from '../../Assets/Logo.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [isMenuOpen, setisMenuOpen] = useState(false);

    const toggleMenu = () => {
        setisMenuOpen(!isMenuOpen);
    }

    const close = () => {
        setisMenuOpen(!isMenuOpen);
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.top_header}>
                    <div className={styles.top_navbar}>
                        <div className={styles.top_nav_icons_holder}>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-solid fa-phone"></i>
                                <p><a href='tel:+91 9730100676'>+91 9730100676</a></p>
                            </div>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-solid fa-envelope"></i>
                                <p><a href='mailto:info@bjshealthcare.com'>info@bjshealthcare.com</a></p>
                            </div>
                        </div>
                        <div className={styles.top_nav_icons_holder}>
                            <div className={styles.top_nav_icon}>
                                <p>Follow Us:</p>
                            </div>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-brands fa-facebook"></i>
                            </div>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-brands fa-instagram"></i>
                            </div>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-brands fa-linkedin"></i>
                            </div>
                            <div className={styles.top_nav_icon}>
                                <i className="fa-brands fa-twitter"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.navbar_wrapper}>
                <div className={styles.navbar}>
                    <div className={styles.logo}>
                        <img src={logo} alt='BJS Healthcare Logo' />
                    </div>

                    <ul className={`${styles.menu} ${isMenuOpen ? styles.menuopen : ''}`}>
                        <Link to='/' className={`${styles.links} ${styles.orange}`} onClick={close}>Home</Link>
                        <Link to='/About' className={styles.links} onClick={close}>About Us</Link>
                        <Link to='/Manufacturing' className={styles.links} onClick={close}>Manufacturing</Link>
                        <Link to='/Products' className={styles.links} onClick={close}>Products</Link>
                        {/* <Link to='/Blog' className={styles.links} onClick={close}>Blog</Link> */}
                        <Link to='/Contact' className={styles.links} onClick={close}>Contact Us</Link>
                    </ul>

                    <div className={`${styles.toggle_bar} ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <div className={styles.bars}></div>
                        <div className={styles.bars}></div>
                        <div className={styles.bars}></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Navbar
